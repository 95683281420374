export class FunctionsTableHeaders {
    allColumns = [
        { field: 'eicCodeTypeObj', header: 'Function Type', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 130, isDate: false},
        { field: 'name', header: 'Function Name', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 200, isDate: false},
        { field: 'functionStatusObj', header: 'Status', visible: true, activeStatus: true, sort: false, filter: true , minWidth: 100, isDate: false},
        { field: 'definition', header: 'Definition', visible: true, activeStatus: true, sort: false, filter: true, minWidth: 150, isDate: false },
        { field: 'creationDate', header: 'Creation Date', dateFormat: "dd-MM-yyyy HH:mm", visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150, isDate: true },
        { field: 'deactivationDate', header: 'Deactivation Date', dateFormat: "dd-MM-yyyy HH:mm", visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150, isDate: true },
        { field: 'functionValidMarketObj', header: 'Valid in Market', visible: true, activeStatus: true, sort: false, filter: true , minWidth: 100, isDate: false},
        { field: 'functionDetail', header: 'Details', visible: true, activeStatus: true, sort: false, filter: false, minWidth: 100, isDate: false }
    ];
}