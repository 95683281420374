import { Injectable } from "@angular/core";
import { TableFilteringSortingOptions } from "../types/export-options-columns.interface";

const columnToFilterMapping = {
    requestActionObj: "requestAction",
    statusObj: "status",
    eicCodeTypeObj: "eicCodeType",
    sourceObj: "source"
};

const filterableDateColumns = [
    "requestDate",
    "deactivationDate",
    "approvalDate",
    "creationDate",
    "updateDate",
    "syncDate",
    "startDate"
]

const filtarableDateColumnToFilterMapping = {
    requestDate : "requestDate_range",
    deactivationDate : "deactivationDate_range",
    approvalDate : "approvalDate_range",
    creationDate : "creationDate_range",
    updateDate : "updateDate_range",
    syncDate : "syncDate_range",
    startDate : "startDate_range"
}

@Injectable({
    providedIn: 'root'
})

export class TableService {

    setFilterPropertyValueForColumn(filterOptions: TableFilteringSortingOptions, columnName: string, value: any): void {
        const filterPropName = columnToFilterMapping[columnName];
        const dateFilterPropName = filtarableDateColumnToFilterMapping[columnName]
        if (filterPropName)
            delete filterOptions[filterPropName]
        else if (filterOptions[columnName])
            delete filterOptions[columnName]
        else if(dateFilterPropName)
            delete filterOptions[dateFilterPropName];
    }

    isFilterableDateColumn(filter: string): boolean {
        return filterableDateColumns.includes(filter);
    }
}

