export class TpExportsTableHeaders {
    defaultColumns = [
        { field: 'eicCode', header: 'EIC Code', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 160, isDate: false},
        { field: 'longName', header: 'EIC Long Name', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150, isDate: false},
        { field: 'displayName', header: 'EIC Display Name', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150, isDate: false },
        { field: 'euvatCode', header: 'EU VAT Code', visible: true, activeStatus: true, sort: true, filter: true , minWidth: 150, isDate: false},
        { field: 'requestActionObj', header: 'Request Action', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150, isDate: false },
        { field: 'statusObj', header: 'Status', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 100, isDate: false },
        { field: 'requestDate', header: 'Request date', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 220, isDate: true },
        { field: 'syncDate', header: 'TP - Last Sync Date', visible: true, activeStatus: true, sort: true, filter: false, minWidth: 220, isDate: true },
        { field: 'state', header: 'TP - Last Sync Status', visible: true, activeStatus: true, sort: true, filter: false, minWidth: 220, isDate: false },
    ];

    additionalColumns = [
        { field: 'creationDate', header: 'Creation date', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 220, isDate: true},
        { field: 'deactivationDate', header: 'Deactivation date', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 220, isDate: true},
        { field: 'approvalDate', header: 'Approval date', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 220, isDate: true},
        { field: 'updateDate', header: 'Update date', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 220, isDate: true},
        { field: 'approvalReason', header: 'Approval reason', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 250, isDate: false},
        { field: 'description', header: 'Description', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 300, isDate: false},
        { field: 'eicCodeTypeObj', header: 'EIC Code Type', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 150, isDate: false},
        { field: 'lioNumber', header: 'LIO Code', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 100, isDate: false},
        { field: 'parent', header: 'EIC Parent', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 300, isDate: false},
        { field: 'responsibleParty', header: 'Responsible Party', visible: false, activeStatus: true, sort: true, filter: true, minWidth: 300, isDate: false },
        { field: 'acerNumber', header: 'ACER Number', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 150, isDate: false},
        { field: 'address', header: 'Address', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 300, isDate: false},
        { field: 'postalCode', header: 'Postal Code', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 300, isDate: false},
        { field: 'city', header: 'City', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 300, isDate: false},
        { field: 'country', header: 'Country', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 300, isDate: false},
        { field: 'functions', header: 'Functions', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 300, isDate: false},
    ];

    allColumns = [...this.defaultColumns, ...this.additionalColumns];
    
}
  