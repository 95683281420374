import { Component, Input, OnInit } from '@angular/core'
import { exportTypeList } from '@app/shared/types/enums/export-types.enum'
import { ExportType } from '@app/shared/types/export-type'

import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { ReportsService } from '@app/shared/services/reports.service'
import { ExportPlace } from '@app/shared/types/enums/export-place.enum'
import { MessageService } from 'primeng/api'
import { Store } from '@ngrx/store'

import * as exportsActions from 'app/store/actions/export.actions'
import { LoaderService } from '@app/shared/services/loader.service'
import { ExportTransparencyPlatformXmlCommand } from '@app/shared/types/commands/exportTransparencyPlatformXmlCommand'

import { saveAs } from 'file-saver'
import { ExportTypeCodes } from '@app/shared/types/enums/export-type-codes.enum'

import * as XLSX from 'xlsx'
import * as JSZip from 'jszip'
import { ExportInterface } from '@app/shared/types/export.interface'
import { EicMarketDocumentXmlNodes } from '@app/shared/types/enums/eic-market-document-xml-nodes'
import { PublicEICStatus } from '@app/shared/types/enums/public-eic-status.enum'
import { ActiveCodeXmlNodes } from '@app/shared/types/enums/active-codes-xml-nodes.enum'
import { DeactivatedCodestXmlNodes } from '@app/shared/types/enums/deactivated-codes-xml-nodes.enum'
import { EICStatusEnum } from '@app/shared/types/enums/eic-status.enum'
import { Column } from '@app/shared/types/column'
import { RequestActionEnum } from '@app/shared/types/enums/request-action.enum'
import * as moment from 'moment-timezone'
import * as vkbeautify from 'vkbeautify';
import { environment } from 'environments/environment'
import { RequestSourceEnum } from '@app/shared/types/enums/request-source.enum'

@Component({
  selector: 'app-export-list',
  templateUrl: './export-list.component.html',
  styleUrls: ['./export-list.component.scss']
})
export class ExportListComponent implements OnInit {
  selectedExport: ExportType | undefined
  exportTypes = exportTypeList
  baseUrl = environment.envBaseUrl

  dateFields = ['approvalDate', 'deactivationDate', 'requestDate', 'creationDate', 'updateDate']

  @Input() exportOptionColumns: TableFilteringSortingOptions
  @Input() exportPlace: ExportPlace
  @Input() selectedExportTypes: any

  @Input() selectedRows: any
  @Input() hideExportSelection: boolean
  @Input() exportBtnDisabled: boolean

  @Input() displayedColumns: Column[]
  @Input() exportOptions: any[]

  constructor(
    private messageService: MessageService,
    private reportService: ReportsService,
    private exportsStore: Store,
    public loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.exportTypes = this.exportTypes.filter((type) => {
      if (this.selectedExportTypes != null && typeof this.selectedExportTypes != typeof undefined) {
        return this.selectedExportTypes.includes(type.value)
      }
      return type
    })

    if (this.exportOptions != null && this.exportOptions.length > 0) {
      this.exportTypes = this.exportTypes.filter((type) => this.exportOptions.includes(type.value))
    }
  }

  exportReport() {
    let exportType = this.selectedExport?.value
    if (!this.hideExportSelection && typeof exportType == typeof undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Select an export type!'
      })
      return
    }

    var props = JSON.parse(JSON.stringify(this.exportOptionColumns))
    props.pageNumber = undefined
    props.rowsPerPage = undefined
    props.getFunctions = true

    Object.keys(props).forEach(function (key) {
      if (props[key] == null || props[key] == undefined) {
        delete props[key]
      }
    })

    let exportObj: ExportInterface = new ExportInterface()
    switch (this.exportPlace) {
      case ExportPlace.ActiveEIC:
        this.reportService.getActiveEICCodes(props).subscribe((data) => {
          exportObj.exportType = exportType
          exportObj.data = data.entities
          exportObj.exportPlace = ExportPlace.ActiveEIC
          exportObj.fileName = 'EICCodes_ActiveCodes'
          exportObj.sheetName = 'Active EIC codes'
          this.exportFile(exportObj)
        })
        break
      case ExportPlace.AllEIC:
        this.reportService.getAllEICCodes(props).subscribe((data) => {
          exportObj.exportType = exportType
          exportObj.data = data.entities
          exportObj.exportPlace = ExportPlace.AllEIC
          exportObj.sheetName = 'All EIC'
          exportObj.fileName = 'EICCodes'
          this.exportFile(exportObj)
        })
        break
      case ExportPlace.DeactivatedCodes:
        this.reportService.getDeactivatedCodes(props).subscribe((data) => {
          exportObj.exportType = exportType
          exportObj.data = data.entities
          exportObj.exportPlace = ExportPlace.DeactivatedCodes
          exportObj.fileName = 'Pending for Deactivation'
          this.exportFile(exportObj)
        })
        break
      case ExportPlace.AllApprovedRequests:
        this.reportService.getAllApprovedRequests(props).subscribe((data) => {
          exportObj.exportType = exportType
          exportObj.data = data.entities
          exportObj.exportPlace = ExportPlace.AllApprovedRequests
          exportObj.zipFileName = 'eic-approved-codes'
          exportObj.sheetName = 'All approved codes'
          exportObj.fileName = 'eic-approved-codes-xsd'
          this.exportFile(exportObj)
        })
        break
      case ExportPlace.TpExport:
        var eicIds: any[] = []
        if (typeof this.selectedRows != typeof undefined && this.selectedRows.length > 0) {
          this.selectedRows.forEach((row) => {
            eicIds.push(row.id)
          })
        }

        var eicIdsObj = {
          eicIds: eicIds
        }
        this.exportsStore.dispatch(exportsActions.exportToTP({ eicIds: eicIdsObj }))
        break
    }
  }

  exportFile(exportObj) {
    let fileContent: string = ''
    let type: string = ''
    let fileName = ''

    switch (exportObj.exportType) {
      case ExportTypeCodes.XML:
        fileContent = this.createXMLFile(exportObj)
        type = 'application/xml'
        fileName = exportObj.fileName + '.xml'
        break
      case ExportTypeCodes.CSV:
        fileContent = this.createCSVFile(exportObj.data)
        type = 'text/csv;charset=UTF-8'
        fileName = exportObj.fileName + '.csv'
        break
      case ExportTypeCodes.EXCEL:
        fileContent = this.createEXCELFile(exportObj.data, exportObj.sheetName)
        type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        fileName = exportObj.fileName + '.xlsx'
        break
      case ExportTypeCodes.ZIP:
        fileContent = this.createXMLFile(exportObj)
        type = 'application/xml'
        fileName = exportObj.fileName + '.xml'
        exportObj.archived = true
        break
    }

    if (exportObj.archived) {
      this.createZipFile(fileName, fileContent, exportObj.zipFileName)
    } else {
      var blob = new Blob([fileContent], { type: type })
      saveAs(blob, fileName)
    }
  }

  createXMLFile(exportObj: ExportInterface) {
    let xmlString = ''
    switch (exportObj.exportPlace) {
      case ExportPlace.ActiveEIC:
        xmlString = this.createActiveEicXml(exportObj.data)
        break
      case ExportPlace.AllEIC:
        xmlString = this.createAllEicXml(exportObj.data)
        break
      case ExportPlace.DeactivatedCodes:
        xmlString = this.createDeactivatedCodesXml(exportObj.data)
        break
      case ExportPlace.AllApprovedRequests:
        xmlString = this.createAllApprovedRequestsXml(exportObj.data)
        break
    }

    return xmlString
  }

  createCSVFile(data: any[]) {
    const utf8BOM = "\uFEFF"; // so that excel interprets the file as utf-8 by default
    const replacer = (key, value) => (value === null ? '' : value);
    const fields = this.getDisplayedTableHeaders('fields');
    const header = this.getDisplayedTableHeaders('headers');
    let csv = data.map((row) =>
      fields
        .map((fieldName) => {
          let field = row[fieldName];
          var key = '';
          if (fieldName == 'requestActionObj') {
            key = 'requestAction';
            field = RequestActionEnum[row[key]];
          } else if (fieldName == 'statusObj') {
            key = 'status';
            field = EICStatusEnum[row[key]];
          } else if (fieldName == 'eicCodeTypeObj') {
            key = 'eicCodeType';
            field = row[key];
          }
          else if (fieldName == 'sourceObj') {
            key = 'source';
            field = RequestSourceEnum[row[key]];
          }
          else if(fieldName=='functions'){
            field = field.map((r) => r.name).toString();
          }

          if (fieldName.toLowerCase().includes('date') && !this.dateFields.includes(fieldName)) {
            field =
              field != null && field != '' ? moment(field).format('ddd, MMM DD, y, h:mm a') : '';
          }

          if (this.dateFields.includes(fieldName)) {
            if (row[fieldName] != null && row[fieldName] != '') {
              const offset = moment['tz'](moment(new Date()), 'Europe/Berlin').utcOffset() * 60000;
              var berlinDateTime = new Date(new Date(row[fieldName]).getTime() + offset);
              field = moment(berlinDateTime).format('ddd, MMM DD, y, h:mm a');
            } else {
              field = '';
            }
          }

          return JSON.stringify(field, replacer);
        })
        .join(';')
    );

    csv.unshift(header.join(';'));
    let csvArray = csv.join('\r\n');
    return utf8BOM+csvArray;
  }

  createEXCELFile(data: any[], sheetName: string | undefined) {
    var json: any[] = []
    var i = 0
    data.forEach((d) => {
      json[i] = {}
      this.displayedColumns.forEach((col) => {
        var key = col.field;
        var header = col.header;

        if (key == 'requestActionObj') {
          key = 'requestAction'
          json[i][header] = RequestActionEnum[d[key]];
        } else if (key == 'statusObj') {
          key = 'status';
          json[i][header] = EICStatusEnum[d[key]];
        } else if (key == 'eicCodeTypeObj') {
          key = 'eicCodeType';
          json[i][header] = d[key];
        } else if (key == 'sourceObj') {
          key = 'source';
          json[i][header] = RequestSourceEnum[d[key]];
        } else {
          let val = d[key];

          if(key == 'functions'){
            json[i][header] = val != undefined ? val.map((r) => r.name).toString() : '';
          }
          else if(key.toLowerCase().includes('date') && !this.dateFields.includes(key)) {
            if (val != undefined) {
              json[i][header] =
                val != null && val != '' ? moment(val).format('ddd, MMM DD, y, h:mm a') : '';
            }
          } else {
            if (this.dateFields.includes(key)) {
              if (val != undefined && val != null && val != '') {
                const offset = moment['tz'](moment(new Date()), 'Europe/Berlin').utcOffset() * 60000;
                var berlinDateTime = new Date(new Date(val).getTime() + offset);
                json[i][header] = moment(berlinDateTime).format('ddd, MMM DD, y, h:mm a');
              } else {
                json[i][header] = '';
              }
            } else {
              json[i][header] = val != undefined ? val : '';
            }
          }
        }
      })
      i++;
    })

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    return excelBuffer
  }

  createZipFile(fileName: string, fileContent: string, zipFileName: string) {
    const jszip = new JSZip()
    jszip.file(fileName, fileContent)

    jszip.generateAsync({ type: 'blob', compression: 'DEFLATE' }).then(function (content) {
      saveAs(content, zipFileName + '.zip')
    })
  }

  createActiveEicXml(data) {
    const xmlDoc = document.implementation.createDocument('', '', null)
    const pi = xmlDoc.createProcessingInstruction('xml', 'version="1.0" encoding="UTF-8"')
    xmlDoc.insertBefore(pi, xmlDoc.firstChild)

    const root = xmlDoc.createElement(ActiveCodeXmlNodes.REPORT)
    root.setAttribute('xsi:schemaLocation',this.baseUrl + ActiveCodeXmlNodes.NAMESPACE_SCHEMA_LOCATION)
    root.setAttribute('Name', ActiveCodeXmlNodes.NAMESPACE_NAME)
    root.setAttribute('xmlns:xsi', ActiveCodeXmlNodes.NAMESPACE_XSI)
    root.setAttribute('xmlns', ActiveCodeXmlNodes.NAMESPACE)

    const lioCodes = xmlDoc.createElement(ActiveCodeXmlNodes.LIO_CODES)
    const officeLioNumberCollection = xmlDoc.createElement(
      ActiveCodeXmlNodes.OFFICE_LIO_NUMBER_COLLECTION
    )

    data.forEach((lio) => {
      const officeLioNumber = xmlDoc.createElement(ActiveCodeXmlNodes.OFFICE_LIO_NUMBER)
      officeLioNumber.setAttribute('OfficeLioNumber', lio.lioNumber)
      const officeLioNameCollection = xmlDoc.createElement(
        ActiveCodeXmlNodes.OFFICE_LIO_NAME_COLLECTION
      )
      const officeLioName = xmlDoc.createElement(ActiveCodeXmlNodes.OFFICE_LIO_NAME)
      officeLioName.setAttribute('OfficeLioName', lio.name)
      const eicStatusCollection = xmlDoc.createElement(ActiveCodeXmlNodes.EIC_STATUS_COLLECTION)
      const eicStatusActive = xmlDoc.createElement(ActiveCodeXmlNodes.EIC_STATUS)
      eicStatusActive.setAttribute('EicStatus', 'Active codes')
      eicStatusActive.setAttribute('OfficeLioName2', lio.activeEic)
      const eicStatusInactive = xmlDoc.createElement(ActiveCodeXmlNodes.EIC_STATUS)
      eicStatusInactive.setAttribute('EicStatus', 'Inactive codes')
      eicStatusInactive.setAttribute('OfficeLioName2', lio.inactiveEic)

      const eicStatusTotal = xmlDoc.createElement(ActiveCodeXmlNodes.EIC_STATUS_TOTAL)
      eicStatusTotal.setAttribute('OfficeLioName4', lio.totalEic)

      const textbox1 = xmlDoc.createElement(ActiveCodeXmlNodes.TEXTBOX1)

      eicStatusCollection.appendChild(eicStatusActive)
      eicStatusCollection.appendChild(eicStatusInactive)
      officeLioName.appendChild(eicStatusCollection)
      officeLioNameCollection.appendChild(officeLioName)
      officeLioNameCollection.appendChild(eicStatusTotal)
      officeLioNameCollection.appendChild(textbox1)
      officeLioNumber.appendChild(officeLioNameCollection)
      officeLioNumberCollection.appendChild(officeLioNumber)
    })

    lioCodes.appendChild(officeLioNumberCollection)
    root.appendChild(lioCodes)
    xmlDoc.appendChild(root)
    const serializer = new XMLSerializer()
    const xmlString = serializer.serializeToString(xmlDoc)

    const prettyXmlString = vkbeautify.xml(xmlString)

    return prettyXmlString
  }

  createAllEicXml(data) {
    const timeElapsed = Date.now()
    const today = new Date(timeElapsed)

    const xmlDoc = document.implementation.createDocument('', '', null)
    const pi = xmlDoc.createProcessingInstruction('xml', 'version="1.0" encoding="UTF-8"')
    xmlDoc.insertBefore(pi, xmlDoc.firstChild)

    const root = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_MARKET_DOCUMENT)
    root.setAttribute('xmlns', EicMarketDocumentXmlNodes.EIC_MARKET_DOCUMENT_NAMESPACE)

    const mRID = xmlDoc.createElement(EicMarketDocumentXmlNodes.MRID)
    const mRID_text = xmlDoc.createTextNode('EIC_doc')
    mRID.appendChild(mRID_text)
    root.appendChild(mRID)

    const revisionNumber = xmlDoc.createElement(EicMarketDocumentXmlNodes.REVISION_NUMBER)
    const revisionNumber_text = xmlDoc.createTextNode('1')
    revisionNumber.appendChild(revisionNumber_text)
    root.appendChild(revisionNumber)

    const type = xmlDoc.createElement(EicMarketDocumentXmlNodes.TYPE)
    const type_text = xmlDoc.createTextNode('B04')
    type.appendChild(type_text)
    root.appendChild(type)

    const senderMarketParticipantId = xmlDoc.createElement(
      EicMarketDocumentXmlNodes.SENDER_MARKET_PARTICIPANT_ID
    )
    senderMarketParticipantId.setAttribute(EicMarketDocumentXmlNodes.CODING_SCHEME_ATTR, 'A01')
    const senderMarketParticipantId_text = xmlDoc.createTextNode('10X1001A1001A450')
    senderMarketParticipantId.appendChild(senderMarketParticipantId_text)
    root.appendChild(senderMarketParticipantId)

    const senderMarketParticipantType = xmlDoc.createElement(
      EicMarketDocumentXmlNodes.SENDER_MARKET_PARTICIPANT_TYPE
    )
    const senderMarketParticipantType_text = xmlDoc.createTextNode('A40')
    senderMarketParticipantType.appendChild(senderMarketParticipantType_text)
    root.appendChild(senderMarketParticipantType)

    const receiverMarketParticipantId = xmlDoc.createElement(
      EicMarketDocumentXmlNodes.RECEIVER_MARKET_PARTICIPANT_ID
    )
    receiverMarketParticipantId.setAttribute(EicMarketDocumentXmlNodes.CODING_SCHEME_ATTR, 'A01')
    const receiverMarketParticipantId_text = xmlDoc.createTextNode('10X1001A1001A450')
    receiverMarketParticipantId.appendChild(receiverMarketParticipantId_text)
    root.appendChild(receiverMarketParticipantId)

    const receiverMarketParticipantType = xmlDoc.createElement(
      EicMarketDocumentXmlNodes.RECEIVER_MARKET_PARTICIPANT_TYPE
    )
    const receiverMarketParticipantType_text = xmlDoc.createTextNode('A41')
    receiverMarketParticipantType.appendChild(receiverMarketParticipantType_text)
    root.appendChild(receiverMarketParticipantType)

    const createdDateTime = xmlDoc.createElement(EicMarketDocumentXmlNodes.CREATED_DATE_TIME)
    const createdDateTime_text = xmlDoc.createTextNode(today.toISOString().split('.')[0] + 'Z')
    createdDateTime.appendChild(createdDateTime_text)
    root.appendChild(createdDateTime)

    if (data && data.length > 0) {
      data.forEach((eic) => {
        const eicCodeMarketDocument = xmlDoc.createElement(
          EicMarketDocumentXmlNodes.EIC_CODE_MARKET_DOCUMENT
        )

        const eicMrid = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_MRID)
        const eicMrid_text = xmlDoc.createTextNode(eic.eicCode)
        eicMrid.appendChild(eicMrid_text)
        eicCodeMarketDocument.appendChild(eicMrid)

        const docStatus = xmlDoc.createElement(EicMarketDocumentXmlNodes.DOC_STATUS)
        const docStatusValue = xmlDoc.createElement(EicMarketDocumentXmlNodes.DOC_STATUS_VALUE)
        const docStatusValue_text = xmlDoc.createTextNode(PublicEICStatus[eic.status])
        docStatusValue.appendChild(docStatusValue_text)
        docStatus.appendChild(docStatusValue)
        eicCodeMarketDocument.appendChild(docStatus)

        const attrInstanceComponent = xmlDoc.createElement(
          EicMarketDocumentXmlNodes.ATTRIBUTE_INSTANCE_COMPONENT
        )
        const attrInstanceComponent_text = xmlDoc.createTextNode('International')
        attrInstanceComponent.appendChild(attrInstanceComponent_text)
        eicCodeMarketDocument.appendChild(attrInstanceComponent)

        const longName = xmlDoc.createElement(EicMarketDocumentXmlNodes.LONG_NAME)
        const longName_text = xmlDoc.createTextNode(eic.longName)
        longName.appendChild(longName_text)
        eicCodeMarketDocument.appendChild(longName)

        const displayName = xmlDoc.createElement(EicMarketDocumentXmlNodes.DISPLAY_NAME)
        const displayName_text = xmlDoc.createTextNode(eic.displayName)
        displayName.appendChild(displayName_text)
        eicCodeMarketDocument.appendChild(displayName)

        const lastRequestDate = xmlDoc.createElement(EicMarketDocumentXmlNodes.LAST_REQUEST_DATE)
        const lastRequestDate_text = xmlDoc.createTextNode(
          this.dateFormat(eic.requestDate, 'yyyy-MM-dd')
        )
        lastRequestDate.appendChild(lastRequestDate_text)
        eicCodeMarketDocument.appendChild(lastRequestDate)

        if (eic.deactivationDate != null && eic.deactivationDate != '') {
          const deactivationDate = xmlDoc.createElement(EicMarketDocumentXmlNodes.DEACTIVATION_DATE)
          const deactivationDate_text = xmlDoc.createTextNode(
            this.dateFormat(eic.deactivationDate, 'yyyy-MM-dd')
          )
          deactivationDate.appendChild(deactivationDate_text)
          eicCodeMarketDocument.appendChild(deactivationDate)
        }

        const contactName = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_CONTACT_NAME)
        eicCodeMarketDocument.appendChild(contactName)

        const contactPhone = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_CONTACT_PHONE)
        const contactPhoneItu = xmlDoc.createElement(
          EicMarketDocumentXmlNodes.EIC_CONTACT_PHONE_ITU
        )
        contactPhone.appendChild(contactPhoneItu)
        eicCodeMarketDocument.appendChild(contactPhone)

        const contactEmail = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_CONTACT_EMAIL)
        const contactEmail1 = xmlDoc.createElement(
          EicMarketDocumentXmlNodes.EIC_CONTACT_EMAIL_EMAIL1
        )
        contactEmail.appendChild(contactEmail1)
        eicCodeMarketDocument.appendChild(contactEmail)

        const contactStreetAddress = xmlDoc.createElement(
          EicMarketDocumentXmlNodes.EIC_STREET_ADDRESS
        )
        const streetDetail = xmlDoc.createElement(EicMarketDocumentXmlNodes.STREET_DETAIL)
        const townDetail = xmlDoc.createElement(EicMarketDocumentXmlNodes.TOWN_DETAIL)
        contactStreetAddress.appendChild(streetDetail)
        contactStreetAddress.appendChild(townDetail)
        eicCodeMarketDocument.appendChild(contactStreetAddress)

        if (eic.euvatCode != null && eic.euvatCode != '') {
          const euVatCode = xmlDoc.createElement(EicMarketDocumentXmlNodes.EU_VAT_CODE)
          const euVatCode_text = xmlDoc.createTextNode(eic.euvatCode)
          euVatCode.appendChild(euVatCode_text)
          eicCodeMarketDocument.appendChild(euVatCode)
        }

        if (eic.parent != null && eic.parent != '') {
          const parent = xmlDoc.createElement(EicMarketDocumentXmlNodes.PARENT_ID)
          const parent_text = xmlDoc.createTextNode(eic.parent)
          parent.appendChild(parent_text)
          eicCodeMarketDocument.appendChild(parent)
        }

        if (eic.responsibleParty != null && eic.responsibleParty != '') {
          const responsibleParty = xmlDoc.createElement(EicMarketDocumentXmlNodes.RESPONSIBLE_PARTY)
          const responsibleParty_text = xmlDoc.createTextNode(eic.responsibleParty)
          responsibleParty.appendChild(responsibleParty_text)
          eicCodeMarketDocument.appendChild(responsibleParty)
        }

        if (eic.description != null && eic.description != '') {
          const description = xmlDoc.createElement(EicMarketDocumentXmlNodes.DESCRIPTION)
          const description_text = xmlDoc.createTextNode(eic.description)
          description.appendChild(description_text)
          eicCodeMarketDocument.appendChild(description)
        }

        if (eic.functions.length > 0) {
          eic.functions.forEach((func) => {
            const functionNames = xmlDoc.createElement(EicMarketDocumentXmlNodes.FUNCTION_NAMES)
            const functionNamesName = xmlDoc.createElement(
              EicMarketDocumentXmlNodes.FUNCTION_NAMES_NAME
            )
            const functionNamesName_text = xmlDoc.createTextNode(func.name)
            functionNamesName.appendChild(functionNamesName_text)
            functionNames.appendChild(functionNamesName)
            eicCodeMarketDocument.appendChild(functionNames)
          })
        } else {
          const functionNames = xmlDoc.createElement(EicMarketDocumentXmlNodes.FUNCTION_NAMES)
          eicCodeMarketDocument.appendChild(functionNames)
        }

        root.appendChild(eicCodeMarketDocument)
      })
    }
    xmlDoc.appendChild(root)

    const serializer = new XMLSerializer()
    const xmlString = serializer.serializeToString(xmlDoc)

    const prettyXmlString = vkbeautify.xml(xmlString)

    return prettyXmlString
  }

  createDeactivatedCodesXml(data) {
    const xmlDoc = document.implementation.createDocument('', '', null)
    const pi = xmlDoc.createProcessingInstruction('xml', 'version="1.0" encoding="UTF-8"')
    xmlDoc.insertBefore(pi, xmlDoc.firstChild)

    const root = xmlDoc.createElement(DeactivatedCodestXmlNodes.REPORT)
    root.setAttribute('xsi:schemaLocation',this.baseUrl + DeactivatedCodestXmlNodes.NAMESPACE_SCHEMA_LOCATION)
    root.setAttribute('Name', DeactivatedCodestXmlNodes.NAMESPACE_NAME)
    root.setAttribute('xmlns:xsi', DeactivatedCodestXmlNodes.NAMESPACE_XSI)
    root.setAttribute('xmlns', DeactivatedCodestXmlNodes.NAMESPACE)

    const tablix1 = xmlDoc.createElement(DeactivatedCodestXmlNodes.TABLIX1)
    const detailsCollection = xmlDoc.createElement(DeactivatedCodestXmlNodes.DEATILS_COLLECTION)

    data.forEach((code, index) => {
      const details = xmlDoc.createElement(DeactivatedCodestXmlNodes.DETAILS)
      details.setAttribute('Textbox10', index + 1)
      details.setAttribute('EicCode', code.eicCode)
      details.setAttribute('EicDisplayName', code.displayName)

      details.setAttribute(
        'Deactivation_Date',
        this.dateFormat(code.deactivationDate, 'dd/MM/yyyy')
      )
      details.setAttribute('Date_of_Approval', this.dateFormat(code.approvalDate, 'dd/MM/yyyy'))
      details.setAttribute('EicLongName', code.longName)
      details.setAttribute('Status', EICStatusEnum[code.status])
      detailsCollection.appendChild(details)
    })

    tablix1.appendChild(detailsCollection)
    root.appendChild(tablix1)
    xmlDoc.appendChild(root)
    const serializer = new XMLSerializer()
    const xmlString = serializer.serializeToString(xmlDoc)

    const prettyXmlString = vkbeautify.xml(xmlString)

    return prettyXmlString
  }

  createAllApprovedRequestsXml(data) {
    const timeElapsed = Date.now()
    const today = new Date(timeElapsed)

    const xmlDoc = document.implementation.createDocument('', '', null)
    const pi = xmlDoc.createProcessingInstruction('xml', 'version="1.0" encoding="UTF-8"')
    xmlDoc.insertBefore(pi, xmlDoc.firstChild)

    const root = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_MARKET_DOCUMENT)
    root.setAttribute('xmlns', EicMarketDocumentXmlNodes.EIC_MARKET_DOCUMENT_NAMESPACE)

    const mRID = xmlDoc.createElement(EicMarketDocumentXmlNodes.MRID)
    const mRID_text = xmlDoc.createTextNode('EIC_doc')
    mRID.appendChild(mRID_text)
    root.appendChild(mRID)

    const revisionNumber = xmlDoc.createElement(EicMarketDocumentXmlNodes.REVISION_NUMBER)
    const revisionNumber_text = xmlDoc.createTextNode('1')
    revisionNumber.appendChild(revisionNumber_text)
    root.appendChild(revisionNumber)

    const type = xmlDoc.createElement(EicMarketDocumentXmlNodes.TYPE)
    const type_text = xmlDoc.createTextNode('B04')
    type.appendChild(type_text)
    root.appendChild(type)

    const senderMarketParticipantId = xmlDoc.createElement(
      EicMarketDocumentXmlNodes.SENDER_MARKET_PARTICIPANT_ID
    )
    senderMarketParticipantId.setAttribute(EicMarketDocumentXmlNodes.CODING_SCHEME_ATTR, 'A01')
    const senderMarketParticipantId_text = xmlDoc.createTextNode('10X1001A1001A450')
    senderMarketParticipantId.appendChild(senderMarketParticipantId_text)
    root.appendChild(senderMarketParticipantId)

    const senderMarketParticipantType = xmlDoc.createElement(
      EicMarketDocumentXmlNodes.SENDER_MARKET_PARTICIPANT_TYPE
    )
    const senderMarketParticipantType_text = xmlDoc.createTextNode('A40')
    senderMarketParticipantType.appendChild(senderMarketParticipantType_text)
    root.appendChild(senderMarketParticipantType)

    const receiverMarketParticipantId = xmlDoc.createElement(
      EicMarketDocumentXmlNodes.RECEIVER_MARKET_PARTICIPANT_ID
    )
    receiverMarketParticipantId.setAttribute(EicMarketDocumentXmlNodes.CODING_SCHEME_ATTR, 'A01')
    const receiverMarketParticipantId_text = xmlDoc.createTextNode('10X1001A1001A450')
    receiverMarketParticipantId.appendChild(receiverMarketParticipantId_text)
    root.appendChild(receiverMarketParticipantId)

    const receiverMarketParticipantType = xmlDoc.createElement(
      EicMarketDocumentXmlNodes.RECEIVER_MARKET_PARTICIPANT_TYPE
    )
    const receiverMarketParticipantType_text = xmlDoc.createTextNode('A41')
    receiverMarketParticipantType.appendChild(receiverMarketParticipantType_text)
    root.appendChild(receiverMarketParticipantType)

    const createdDateTime = xmlDoc.createElement(EicMarketDocumentXmlNodes.CREATED_DATE_TIME)
    const createdDateTime_text = xmlDoc.createTextNode(today.toISOString().split('.')[0] + 'Z')
    createdDateTime.appendChild(createdDateTime_text)
    root.appendChild(createdDateTime)

    if (data && data.length > 0) {
      data.forEach((eic) => {
        const eicCodeMarketDocument = xmlDoc.createElement(
          EicMarketDocumentXmlNodes.EIC_CODE_MARKET_DOCUMENT
        )

        const eicMrid = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_MRID)
        const eicMrid_text = xmlDoc.createTextNode(eic.eicCode)
        eicMrid.appendChild(eicMrid_text)
        eicCodeMarketDocument.appendChild(eicMrid)

        const docStatus = xmlDoc.createElement(EicMarketDocumentXmlNodes.DOC_STATUS)
        const docStatusValue = xmlDoc.createElement(EicMarketDocumentXmlNodes.DOC_STATUS_VALUE)
        const docStatusValue_text = xmlDoc.createTextNode(PublicEICStatus[eic.status])
        docStatusValue.appendChild(docStatusValue_text)
        docStatus.appendChild(docStatusValue)
        eicCodeMarketDocument.appendChild(docStatus)

        const attrInstanceComponent = xmlDoc.createElement(
          EicMarketDocumentXmlNodes.ATTRIBUTE_INSTANCE_COMPONENT
        )
        const attrInstanceComponent_text = xmlDoc.createTextNode('International')
        attrInstanceComponent.appendChild(attrInstanceComponent_text)
        eicCodeMarketDocument.appendChild(attrInstanceComponent)

        const longName = xmlDoc.createElement(EicMarketDocumentXmlNodes.LONG_NAME)
        if(eic.longName.includes('\'\''))
          eic.longName = eic.longName.replaceAll('\'\'','"""')
        const longName_text = xmlDoc.createTextNode(eic.longName)
        longName.appendChild(longName_text)
        eicCodeMarketDocument.appendChild(longName)

        const displayName = xmlDoc.createElement(EicMarketDocumentXmlNodes.DISPLAY_NAME)
        const displayName_text = xmlDoc.createTextNode(eic.displayName)
        displayName.appendChild(displayName_text)
        eicCodeMarketDocument.appendChild(displayName)

        const lastRequestDate = xmlDoc.createElement(EicMarketDocumentXmlNodes.LAST_REQUEST_DATE)
        const lastRequestDate_text = xmlDoc.createTextNode(
          this.dateFormat(eic.requestDate, 'yyyy-MM-dd')
        )
        lastRequestDate.appendChild(lastRequestDate_text)
        eicCodeMarketDocument.appendChild(lastRequestDate)

        if (eic.deactivationDate != null && eic.deactivationDate != '') {
          const deactivationDate = xmlDoc.createElement(EicMarketDocumentXmlNodes.DEACTIVATION_DATE)
          const deactivationDate_text = xmlDoc.createTextNode(
            this.dateFormat(eic.deactivationDate, 'yyyy-MM-dd')
          )
          deactivationDate.appendChild(deactivationDate_text)
          eicCodeMarketDocument.appendChild(deactivationDate)
        }
        
        if (eic.contactDetailsName != null && eic.contactDetailsName != '') {
          const contactName = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_CONTACT_NAME)
          const contactName_text = xmlDoc.createTextNode(eic.contactDetailsName)
          contactName.appendChild(contactName_text)
          eicCodeMarketDocument.appendChild(contactName)
        }

        const contactPhone = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_CONTACT_PHONE)
        if (eic.contactDetailsPhoneNumber != null && eic.contactDetailsPhoneNumber != '') {
          const contactPhoneItu = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_CONTACT_PHONE_ITU)
          const contactPhone_text = xmlDoc.createTextNode(eic.contactDetailsPhoneNumber)
          contactPhoneItu.appendChild(contactPhone_text)
          contactPhone.appendChild(contactPhoneItu)
        }
        eicCodeMarketDocument.appendChild(contactPhone)

        const contactEmail = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_CONTACT_EMAIL)
        if (eic.contactDetailsEmail != null && eic.contactDetailsEmail != '') {
          const contactEmail1 = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_CONTACT_EMAIL_EMAIL1)
          const contactEmail_text = xmlDoc.createTextNode(eic.contactDetailsEmail)
          contactEmail1.appendChild(contactEmail_text)          
          contactEmail.appendChild(contactEmail1)
        }
        eicCodeMarketDocument.appendChild(contactEmail)

        const contactStreetAddress = xmlDoc.createElement(EicMarketDocumentXmlNodes.EIC_STREET_ADDRESS)
        
        if (eic.address != null && eic.address != '') {
          const streetDetail = xmlDoc.createElement(EicMarketDocumentXmlNodes.STREET_DETAIL)
          const addressGeneralDetail = xmlDoc.createElement(EicMarketDocumentXmlNodes.ADDRESS_GENERAL)
          const address_text = xmlDoc.createTextNode(eic.address)
          addressGeneralDetail.appendChild(address_text)
          streetDetail.appendChild(addressGeneralDetail)          
          contactStreetAddress.appendChild(streetDetail)
        }

        if (eic.postalCode != null && eic.postalCode != '') {
          const postalCode = xmlDoc.createElement(EicMarketDocumentXmlNodes.POSTAL_CODE)
          const postalCode_text = xmlDoc.createTextNode(eic.postalCode)
          postalCode.appendChild(postalCode_text)
          contactStreetAddress.appendChild(postalCode)
        }

        if ((eic.city != null && eic.city != '') || (eic.country != null && eic.country != '')) {
          const townDetail = xmlDoc.createElement(EicMarketDocumentXmlNodes.TOWN_DETAIL)

          if (eic.city != null && eic.city != '') {
            const townName = xmlDoc.createElement(EicMarketDocumentXmlNodes.TOWN_NAME)
            const town_text = xmlDoc.createTextNode(eic.city)
            townName.appendChild(town_text)
            townDetail.appendChild(townName)
          }
          if (eic.country != null && eic.country != '') {
            const country = xmlDoc.createElement(EicMarketDocumentXmlNodes.TOWN_COUNTRY)
            const country_text = xmlDoc.createTextNode(eic.country)
            country.appendChild(country_text)
            townDetail.appendChild(country)
          }          
          contactStreetAddress.appendChild(townDetail)
        }
        
        eicCodeMarketDocument.appendChild(contactStreetAddress)

        if (eic.euvatCode != null && eic.euvatCode != '') {
          const euVatCode = xmlDoc.createElement(EicMarketDocumentXmlNodes.EU_VAT_CODE)
          const euVatCode_text = xmlDoc.createTextNode(eic.euvatCode)
          euVatCode.appendChild(euVatCode_text)
          eicCodeMarketDocument.appendChild(euVatCode)
        }

        if (eic.acerNumber != null && eic.acerNumber != '') {
          const acerNumber = xmlDoc.createElement(EicMarketDocumentXmlNodes.ACER_NUMBER)
          const acerNumber_text = xmlDoc.createTextNode(eic.acerNumber)
          acerNumber.appendChild(acerNumber_text)
          eicCodeMarketDocument.appendChild(acerNumber)
        }

        if (eic.parent != null && eic.parent != '') {
          const eicParent = xmlDoc.createElement(EicMarketDocumentXmlNodes.PARENT_ID)
          const eicParent_text = xmlDoc.createTextNode(eic.parent)
          eicParent.appendChild(eicParent_text)
          eicCodeMarketDocument.appendChild(eicParent)
        }
        
        if (eic.responsibleParty != null && eic.responsibleParty != '') {
          const responsibleParty = xmlDoc.createElement(EicMarketDocumentXmlNodes.RESPONSIBLE_PARTY)
          const responsibleParty_text = xmlDoc.createTextNode(eic.responsibleParty)
          responsibleParty.appendChild(responsibleParty_text)
          eicCodeMarketDocument.appendChild(responsibleParty)
        }

        if (eic.description != null && eic.description != '') {
          const description = xmlDoc.createElement(EicMarketDocumentXmlNodes.DESCRIPTION)
          if(eic.description.includes('\'\''))
            eic.description = eic.description.replaceAll('\'\'','"""')
          const description_text = xmlDoc.createTextNode(eic.description)
          description.appendChild(description_text)
          eicCodeMarketDocument.appendChild(description)
        }

        if (eic.functions.length > 0) {
          eic.functions.forEach((func) => {
            const functionNames = xmlDoc.createElement(EicMarketDocumentXmlNodes.FUNCTION_NAMES)
            const functionNamesName = xmlDoc.createElement(
              EicMarketDocumentXmlNodes.FUNCTION_NAMES_NAME
            )
            const functionNamesName_text = xmlDoc.createTextNode(func.name)
            functionNamesName.appendChild(functionNamesName_text)
            functionNames.appendChild(functionNamesName)
            eicCodeMarketDocument.appendChild(functionNames)
          })
        } else {
          const functionNames = xmlDoc.createElement(EicMarketDocumentXmlNodes.FUNCTION_NAMES)
          eicCodeMarketDocument.appendChild(functionNames)
        }
        root.appendChild(eicCodeMarketDocument)
      })
    }

    xmlDoc.appendChild(root)
    const serializer = new XMLSerializer()
    let xmlString = serializer.serializeToString(xmlDoc)

    // workaround to replace double quotes with &quot; without the serializer replacing the & with &amp;
    xmlString = xmlString.replaceAll('"""','&quot;')

    const prettyXmlString = vkbeautify.xml(xmlString)

    return prettyXmlString
  }

  getDisplayedTableHeaders(type) {
    var displayed: string[] = []
    if (type == 'fields') {
      this.displayedColumns.forEach((col) => {
        displayed.push(col.field)
      })
    } else if (type == 'headers') {
      this.displayedColumns.forEach((col) => {
        displayed.push(col.header)
      })
    }
    return displayed
  }

  dateFormat(date, format) {
    let requestDate = new Date(date)
    let tempDay = requestDate.getDate()
    let tempMonth = requestDate.getMonth() + 1
    let day = tempDay < 10 ? `0${tempDay}` : tempDay
    let month = tempMonth < 10 ? `0${tempMonth}` : tempMonth
    let year = requestDate.getFullYear()

    if (format == 'yyyy-MM-dd') {
      return `${year}-${month}-${day}`
    } else if (format == 'dd/MM/yyyy') {
      return `${day}-${month}-${year}`
    }
    return ''
  }
}
