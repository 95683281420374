import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { ApproveRequestInterface } from '@app/shared/types/approve-request.interface'
import { BulkApproveRequestsInterface } from '@app/shared/types/bulk-approve-requests.interface'
import { PendingRequestActionEnum } from '@app/shared/types/enums/pending-request-action.enum'
import { RequestStateInterface } from '@app/shared/types/request-state.interface'
import { approvePendingRequest, bulkApprovePendingRequest } from '@app/store/actions/request.actions'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Observable, Subject, Subscription, takeUntil } from 'rxjs'
import * as RequestActions from '../../../store/actions/request.actions'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { StandardApprovalAnswer } from '@app/shared/types/standard-approval-answer.interface'
import { standardAnswersSelectorForAction } from '@app/store/selectors/request.selector'

@Component({
  selector: 'app-modal-change-status',
  templateUrl: './modal-change-status.component.html',
  styleUrls: ['./modal-change-status.component.scss']
})
export class ModalChangeStatusComponent implements OnDestroy {
  visible: boolean = false
  action: string = 'approve'
  standardAnswers: StandardApprovalAnswer[]
  answersSub: Subscription
  standardAnswers$: Observable<StandardApprovalAnswer[]>

  @Input() label: string
  @Input() title: string
  @Input() subTitle: string
  @Input() requestId!: number;
  @Input() requestIds!: number[];
  @Input() selectedAction!: PendingRequestActionEnum
  @Input() manual: boolean
  @Input() eicCodes: string[]
  @Input() tableFilterSortingOptions: TableFilteringSortingOptions;
  @Input() isBulk: boolean = false;

  @Output("onSubmitApproval") onSubmitApproval: EventEmitter<any> = new EventEmitter();

  approveForm: FormGroup
  destroyed$ = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<RequestStateInterface>,
    private updates$: Actions,
  ) {
    this.approveForm = this.formBuilder.group({
      comment: new FormControl(null)
    })
  }

  ngOnInit() {}


  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  showDialog({
    eicCodes,
    requestIds,
    selectedAction,
    title = '',
    subTitle = '',
    label = '',
    manual = false
  }: any) {
    this.eicCodes = [...eicCodes]
    this.title = title
    this.subTitle = subTitle
    this.label = label
    this.manual = manual
    this.requestIds = [...requestIds]
    this.selectedAction = selectedAction

    this.visible = true

    this.standardAnswers$ = this.store.select(standardAnswersSelectorForAction(this.selectedAction))

    this.answersSub = this.standardAnswers$.subscribe(data =>{
      this.standardAnswers = [...data]
    })

    if(this.standardAnswers != null && this.standardAnswers.length > 0){
      this.approveForm.controls['comment'].setValue(this.standardAnswers[0].text)
    }
  }

  onSubmit() {
    if (this.isBulk) {
      let bulkApprovalRequestData: BulkApproveRequestsInterface = { 
          requestIds: this.requestIds,
          approvalReason: this.approveForm.controls['comment'].value,
          pendingRequestAction: this.selectedAction
      };
      this.store.dispatch(bulkApprovePendingRequest({ bulkApprovalRequestData }))
      
      //if bulk approve/reject we must refresh the table as we are still on the same page and not navigating away to reload the page.
      this.updates$
      .pipe(ofType(RequestActions.BULK_APPROVE_REJECT_REQUEST_SUCCESSFUL), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.store.dispatch(
          RequestActions.getAllRequests({ tableOptions: this.tableFilterSortingOptions})
        )
        this.visible = false;
        this.approveForm.reset();
        this.onSubmitApproval.emit();
      });
    } else {
      let approvalRequestData: ApproveRequestInterface = {
        requestId: this.requestIds[0],
        approvalReason: this.approveForm.controls['comment'].value,
        pendingRequestAction: this.selectedAction
      }
  
      this.store.dispatch(approvePendingRequest({ approvalRequestData }))
    }
  } 

  get PendingRequestActionEnum() {
    return PendingRequestActionEnum
  }
}
