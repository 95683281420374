import { Injectable } from '@angular/core'
import { merge, of } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, mergeMap, catchError } from 'rxjs/operators'
import * as messageActions from '../actions/message.action'
import { MessageService } from 'primeng/api'
import { HomepageMessageService } from '@app/shared/services/homepage-message.service'

@Injectable()
export class MessageEffect {
  constructor(
    private actions$: Actions,
    private homepageMessageService: HomepageMessageService,
    private messageService: MessageService
  ) {}

  getMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageActions.getMessages),
      mergeMap(() => {
        return this.homepageMessageService.getMessages().pipe(
          map((messages) => messageActions.getMessagesSuccesfully({ messages })),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get messages failed.'
            })
            return of(messageActions.getMessagesFailed({ error: error.message }))
          })
        )
      })
    )
  )

  getAllMessages = createEffect(() =>
  this.actions$.pipe(
    ofType(messageActions.getAllMessages),
    mergeMap(({tableOptions}) =>{
      return this.homepageMessageService.getAllMessages(tableOptions).pipe( 
        map((paginatedResult) => messageActions.getAllMessagesSuccesfully({paginatedResult})),
        catchError((error) =>{
          this.messageService.add({
            key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get messages failed.'
          })
          return of(messageActions.getAllMessagesFailed({error: error.message}))
        })
      )
    })
  ))

  createMessage = createEffect(() =>
  this.actions$.pipe(
    ofType(messageActions.createMessage),
    mergeMap(({message : message}) => {
      return this.homepageMessageService.createMessage(message).pipe(
        map(() => {
          this.messageService.add({
            key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail: 'Message is successfully created'
          })
          return messageActions.createMessageSuccesfully()
        }),
        catchError((error) => {
          this.messageService.add({
            key: 'toast',
              severity: 'error',
              summary: 'Message creation failed.',
              detail: error.message
          })
          return of(messageActions.createMessageFailed(error))
        })
      )
    })
  ))

  updateMessage$ = createEffect(() => 
    this.actions$.pipe(
      ofType(messageActions.updateMessage),
      mergeMap(({message: message}) => {
        return this.homepageMessageService.updateMessage(message).pipe(
          map(() => {
            this.messageService.add({
              key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail: 'Message is successfully changed'
            })
            return messageActions.updateMessageSuccesfully()
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Message change failed.',
              detail: error.message
            })
            return of(messageActions.updateMessageFailed(error))
          })
        )
      })
    )
  )
}
