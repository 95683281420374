import { Component, Input, ViewChild } from '@angular/core'
import { ModalChangeStatusComponent } from '@app-components/modal/modal-change-status/modal-change-status.component'
import { CreateRequestComponent } from '@app/views/request/create-request/create-request.component'
import { ActivatedRoute, Router } from '@angular/router'
import { RequestInterface } from '@app-shared-types/request.interface'
import { Observable, Subscription } from 'rxjs'
import {
  requestStatusFilterOptions,
  requestStatusList
} from '@app-shared-types/enums/request-status.enum'
import {
  requestActionFilterOptions,
  requestActionsList
} from '@app-shared-types/enums/request-action.enum'
import { RequestStateInterface } from '@app/shared/types/request-state.interface'
import { Store, select } from '@ngrx/store'
import {
  requestsOfLio,
  requestsSelector,
  totalRecordsSelector
} from '@app/store/selectors/request.selector'
import * as RequestActions from '../../../../store/actions/request.actions'
import { VisibilityColumnsHeader } from '@app/shared/types/visibility-columns-header.interface'
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum'
import { ModalUploadXmlDocumentComponent } from '@app/components/modal/modal-upload-xml-document/modal-upload-xml-document.component'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { requestSourceFilterOptions, requestSourceList } from '@app/shared/types/enums/request-source.enum'
import { eicCodeTypesList } from '@app/shared/types/enums/eic-code-type.enum'

@Component({
  selector: 'app-lio-request-overview',
  templateUrl: './lio-request-overview.component.html',
  styleUrls: ['./lio-request-overview.component.scss']
})
export class LioRequestOverviewComponent {
  @ViewChild('modalCreateRequestComponent') modalCreateRequestComponent: CreateRequestComponent
  @ViewChild('modalUploadXMLDocumentComponent') modalUploadXMLDocumentComponent: ModalUploadXmlDocumentComponent

  lioNumber: number
  requestsOfLio$: Observable<RequestInterface[]>
  requestsOfLioSub: Subscription
  requests: RequestInterface[]
  allRequests: RequestInterface[] = []

  requestStatusList = requestStatusList
  requestActionsList = requestActionsList
  requestSourceList = requestSourceList
  requestStatusFilterOptions = requestStatusFilterOptions
  requestActionFilterOptions = requestActionFilterOptions
  requestSourceFilterOptions = requestSourceFilterOptions
  eicCodeTypesOptions = eicCodeTypesList

  visibilityColumnsHeaderObj: VisibilityColumnsHeader = new VisibilityColumnsHeader()
  settingType: SettingsTypes

  pageNumber: number | undefined = 0
  rowsPerPage: number | undefined = 10

  totalRecords$: Observable<number>
  totalRecords: number
  firstRow: number = 0
  querySub: Subscription
  recordSub: Subscription

  tableFilterOrSortOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions()

  constructor(
    private route: ActivatedRoute,
    private store: Store<RequestStateInterface>,
    private router: Router
  ) {
    this.lioNumber = parseInt(this.route.snapshot.paramMap.get('lioNumber')!, 10)
    this.requestsOfLio$ = this.store.select(requestsSelector)
    this.totalRecords$ = this.store.pipe(select(totalRecordsSelector))
    this.requestsOfLio$ = this.store.select(requestsOfLio(this.lioNumber))
  }

  ngOnInit() {

    let paramsOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions();
    this.querySub = this.route.queryParams
      .subscribe(params => {
        Object.keys(params).forEach(key => {
          if (params[key] != null) {

            switch (key) {
              case "requestAction":
                if (params[key] != undefined) {
                  paramsOptions[key] = ""
                  params[key].split(",").forEach(requestActionLabel => {
                    paramsOptions[key] += requestActionsList.find((item) => item.label == requestActionLabel)?.value + ","
                  })
                  paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
                }
                break;
              case "status":
                if (params[key] != undefined) {
                  paramsOptions[key] = ""
                  params[key].split(",").forEach(requestStatusLabel => {
                    paramsOptions[key] += requestStatusList.find((item) => item.label == requestStatusLabel)?.value + ","
                  })
                  paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
                }
                break;
              default:
                paramsOptions[key] = params[key]
                break;
            }
          }
        })
      })
    this.pageNumber = paramsOptions.pageNumber
    this.rowsPerPage = paramsOptions.rowsPerPage

    if (!this.pageNumber || !this.rowsPerPage) {
      this.pageNumber = 0
      this.rowsPerPage = 10
    }

    this.firstRow = this.rowsPerPage * this.pageNumber
    this.rowsPerPage = parseInt(this.rowsPerPage?.toString(), 10)
    
    this.tableFilterOrSortOptions.pageNumber = this.pageNumber
    this.tableFilterOrSortOptions.rowsPerPage = this.rowsPerPage

    this.recordSub = this.totalRecords$.subscribe((total) => {
      this.totalRecords = total
    })

    Object.keys(paramsOptions).forEach(key => {
      if (paramsOptions[key] != null) {
        this.tableFilterOrSortOptions[key] = paramsOptions[key]
      }
    })

    this.getData(this.tableFilterOrSortOptions)

    this.settingType = SettingsTypes.lioRequestsTableHeaders
    this.visibilityColumnsHeaderObj.optionDisabled = 'activeStatus'
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {
    this.pageNumber = tableFilterOrSortOptions.pageNumber
    this.rowsPerPage = tableFilterOrSortOptions.rowsPerPage

    let props = tableFilterOrSortOptions
    Object.keys(props).forEach(function (key) {
      if (props[key] == null) {
        delete props[key]
      }
    })
    this.tableFilterOrSortOptions = props
    this.getData(props)
  }

  setQueryParams() {
    let queryParams: TableFilteringSortingOptions = new TableFilteringSortingOptions()

    Object.keys(this.tableFilterOrSortOptions).forEach(key => {
      if (this.tableFilterOrSortOptions[key] != null) {
        if (key != "sortBy") {

          switch (key) {
            case "requestAction":
              if (this.tableFilterOrSortOptions[key] != undefined) {
                queryParams[key] = ""
                this.tableFilterOrSortOptions[key]?.split(",").forEach(requestAction => {
                  queryParams[key] += requestActionsList[requestAction].label + ','
                })
                queryParams[key] = queryParams[key]?.slice(0, -1)
              }
              break;
            case "status":
              if (this.tableFilterOrSortOptions[key] != undefined) {
                queryParams[key] = ""
                this.tableFilterOrSortOptions[key]?.split(",").forEach(requestStatus => {
                  queryParams[key] += requestStatusList[requestStatus].label + ','
                })
                queryParams[key] = queryParams[key]?.slice(0, -1)
              }
              break;
            default:
              queryParams[key] = this.tableFilterOrSortOptions[key]
              break;
          }
        }
      }
    });

    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })
  }

  ngOnDestroy() {
    this.querySub.unsubscribe()
    this.recordSub.unsubscribe()
    this.requestsOfLioSub.unsubscribe()
  }

  getData(tableOptions: TableFilteringSortingOptions) {
    this.store.dispatch(
      RequestActions.getRequestsOfLio({ lioNumber: this.lioNumber, tableOptions: tableOptions })
    )

    this.requestsOfLioSub = this.requestsOfLio$.subscribe((data) => {
      this.requests = data.map((item) => {
        let req = Object.assign({}, item, {
          statusObj: {
            label: requestStatusList[item.status].label,
            value: item.status
          },
          requestActionObj: {
            label: requestActionsList[item.requestAction].label,
            value: item.requestAction
          },
          sourceObj: {
            label: item.source != null ? requestSourceList[item.source].label : '',
            value: item.source != null ? item.source : ''
          },
          eicCodeTypeObj: {
            label: eicCodeTypesList.filter((obj) => obj.value == item.eicCodeType)[0].label,
            value: item.eicCodeType
          },
          itemUrl: 'lio/' + item.lioNumber + '/requests/' + item.id?.toString()
        })
        return req
      })
      this.allRequests = this.requests
    })
    this.setQueryParams()
  }

  redirectToItem($event: any) {
    if ($event.data.itemUrl) {
      this.router.navigate([$event.data.itemUrl]);
    }
  }

  openCreateModal(){
    this.modalCreateRequestComponent.showDialog({
      lioNumber: this.lioNumber,
      tableFilterOrSortOptions: this.tableFilterOrSortOptions
    })
  }

  openXmlUploadModal(){
    this.modalUploadXMLDocumentComponent.showDialog({
      lioNumber: this.lioNumber,
      tableFilterOrSortOptions: this.tableFilterOrSortOptions
    })
  }
}
