export class AllApprovedRequestsReportTableHeaders {
  allColumns = [
    {
      field: 'eicCode',
      header: 'EIC Code',
      visible: true,
      activeStatus: true,
      sort: false,
      filter: false,
      minWidth: 160,
      isDate: false
    },
    {
      field: 'longName',
      header: 'EIC Long Name',
      visible: true,
      activeStatus: true,
      sort: false,
      filter: false,
      minWidth: 150,
      isDate: false
    },
    {
      field: 'displayName',
      header: 'EIC Display Name',
      visible: true,
      activeStatus: false,
      sort: false,
      filter: false,
      minWidth: 150,
      isDate: false
    },
    {
      field: 'euvatCode',
      header: 'EU VAT Code',
      visible: true,
      activeStatus: true,
      sort: false,
      filter: false,
      minWidth: 150,
      isDate: false
    },
    {
      field: 'requestActionObj',
      header: 'Request Action',
      visible: true,
      activeStatus: true,
      sort: false,
      filter: false,
      minWidth: 150,
      isDate: false
    },
    {
      field: 'requestDate',
      header: 'Request Date',
      visible: true,
      activeStatus: true,
      sort: false,
      filter: true,
      minWidth: 220,
      isDate: true
    }
  ]
}
