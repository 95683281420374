import { Component, Input } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { EICStateInterface } from '@app/shared/types/eic-state.interface';
import { eicCodeTypesList } from '@app/shared/types/enums/eic-code-type.enum'
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import * as eicActions from '@app-store/actions/eic.actions'
import { Actions, ofType } from '@ngrx/effects';
import { Subject, take, takeUntil } from 'rxjs';
import { FunctionStateInterface } from '@app/shared/types/function.state.interface';
import { functionsSelector } from '@app/store/selectors/function.selector';
import { FunctionInterface } from '@app/shared/types/function.interface';
import { FunctionStatusEnum } from '@app/shared/types/enums/function-status.enum';

@Component({
  selector: 'app-allocated-eic-code',
  templateUrl: './allocated-eic-code.component.html',
  styleUrls: ['./allocated-eic-code.component.scss']
})
export class AllocatedEicCodeComponent {

  @Input() requestForm: FormGroup;
  @Input() formType: string;

  allFunctions: FunctionInterface[];
  destroyed$ = new Subject<boolean>();
  codeFunctionOptions = eicCodeTypesList;

  constructor(private formBuilder: FormBuilder,
    private messageService: MessageService,
    private store: Store<EICStateInterface>,
    private functionStore: Store<FunctionStateInterface>,
    private updates$: Actions){

      this.functionStore.select(functionsSelector).subscribe(fct => this.allFunctions = fct);
    }

  ngOnInit() {
  }

  generateEIC(){
    var codeType = this.requestForm.controls['eicCodeType'];
    if(codeType.valid){
      this.store.dispatch(eicActions.generateEIC({codeType: codeType.value}))
      this.updates$.pipe(
        ofType(eicActions.GENERATE_EIC_SUCCESSFUL),
        takeUntil(this.destroyed$)
      )
      .subscribe((data: any) => {
        let eic = data.eic;
        this.requestForm.controls['eicRandomSequence'].setValue(eic.substring(3,15));
        this.requestForm.controls['eicCheckKey'].setValue(eic.substring(15,16));
      });
      this.updates$.pipe(
        ofType(eicActions.GENERATE_EIC_FAILED),
        takeUntil(this.destroyed$)
      )
      .subscribe((data: any) => {
        let eic = data.eicCode;
        this.requestForm.controls['eicRandomSequence'].setValue(eic.substring(3,15));
        this.requestForm.controls['eicRandomSequence'].setErrors(null)
        this.requestForm.controls['eicCheckKey'].setValue(null);
        this.requestForm.controls['eicCheckKey'].markAsDirty()
        this.requestForm.controls['eicCheckKey'].setErrors({notValidCode: true})
      });
    }
    else{
      this.messageService.add({ 
        severity: 'error', 
        summary: '', 
        detail: 'You need to choose a code type in order to generate the EIC.'
      });
    }
  }

  validateOrGenerateCheckKey(controlName :string){
    
    if(controlName != 'eicCheckKey')
      this.requestForm.controls['eicCheckKey']?.reset()

    var codeType = this.requestForm.controls['eicCodeType'];
    var randomSequence = this.requestForm.controls['eicRandomSequence']?.value;
    var checkKey = this.requestForm.controls['eicCheckKey']?.value;

    var randomSequenceIsFilledIn = randomSequence?.length==12
    var checkKeyIsFilledIn = checkKey?.length==1

    // randomSequence and checkKey only need to be filled in (not valid) to validate/ generateCheckDigit
    if(codeType.valid && randomSequenceIsFilledIn && checkKeyIsFilledIn){
      this.validateEIC();
    }
    else if(codeType.valid && randomSequenceIsFilledIn){
      this.generateCheckDigit();
    }
  }

  generateCheckDigit(){
    var eic = this.getFullEIC();
    this.store.dispatch(eicActions.generateEicCheckKey({eic}))
    this.updates$.pipe(
      ofType(eicActions.GENERATE_EIC_CHECK_KEY_SUCCESSFUL),
      take(1)
    )
    .subscribe((data: any) => {
      let checkKey = data.checkKey;
      this.requestForm.controls['eicCheckKey'].setValue(checkKey);
      this.requestForm.controls['eicRandomSequence'].setErrors(null)
      this.requestForm.controls['eicCheckKey'].setErrors(null)
    });

    this.updates$.pipe(
      ofType(eicActions.GENERATE_EIC_CHECK_KEY_FAILED),
      take(1)
    )
    .subscribe((data: any) => {
      this.requestForm.controls['eicCheckKey'].markAsDirty()
      this.requestForm.controls['eicCheckKey'].setErrors({notValidCode: true})
    });
  }

  validateEIC(){
    var eic = this.getFullEIC();
   
    this.store.dispatch(eicActions.validateEic({eic}))
    this.updates$.pipe(
      ofType(eicActions.VALIDATE_EIC_SUCCESSFUL),
      takeUntil(this.destroyed$)
    )
    .subscribe((data: any) => {
      let isValid = data.isValid;
      this.messageService.clear()
      if(!isValid){
        this.messageService.add({ 
          severity: 'error', 
          summary: '', 
          detail: 'This EIC is not valid!'
        });
        this.requestForm.controls['eicRandomSequence'].setErrors({notValidCode: true})
        this.requestForm.controls['eicCheckKey'].setErrors({notValidCode: true})
      }
      else{
        this.requestForm.controls['eicRandomSequence'].setErrors(null)
        this.requestForm.controls['eicCheckKey'].setErrors(null)
      }
    });
    
  }

  getFullEIC(){
    return this.requestForm.controls['lioNumber'].value +
      (this.requestForm.controls['eicCodeType'].value ?? '') +
      (this.requestForm.controls['eicRandomSequence'].value ?? '') + 
      (this.requestForm.controls['eicCheckKey'].value ?? '');
  }

  initFunctions(){

    this.functions.clear();

    var eligibleFunctions = this.allFunctions.filter(f => f.code == this.requestForm.controls['eicCodeType'].value && 
    f.status == FunctionStatusEnum.Active)

    eligibleFunctions.forEach(
      f => {  
        const functionForm : FormGroup = this.formBuilder.group({
          id: f.id,
          functionName: [f.name],
          isSelected: false
        }) as FormGroup;
        this.functions.push(functionForm);
      }
    )
  }

  get functions() {
    return this.requestForm.get('functions') as FormArray;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
