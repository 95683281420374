import { BulkRequestsInterface } from './../../shared/types/bulk-requests.interface'
import { CreateRequestCommand } from './../../shared/types/commands/createRequestCommand.model'
import { createAction, props } from '@ngrx/store'
import { RequestInterface } from '@app-shared-types/request.interface'
import { ApproveRequestInterface } from '@app-shared-types/approve-request.interface'
import { UpdateRequestCommand } from '@app/shared/types/commands/updateRequestCommand.model'
import { PaginatedResult } from '@app/shared/types/paginated-result.interface'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { BulkRequestStatus } from '@app/shared/types/bulk-request-status-interface'
import { BulkApproveRequestsInterface } from '@app/shared/types/bulk-approve-requests.interface'
import { PendingRequestActionEnum } from '@app/shared/types/enums/pending-request-action.enum'
import { StandardApprovalAnswer } from '@app/shared/types/standard-approval-answer.interface'

export const CREATE_REQUEST = '[REQUEST Component] Create request'
export const CREATE_REQUEST_SUCCESSFUL = '[REQUEST Component] Create request succes'
export const CREATE_REQUEST_FAILED = '[REQUEST Component] Create request failed'

export const CREATE_REQUESTS = '[REQUEST Component] Create requests'
export const CREATE_REQUESTS_SUCCESSFUL = '[REQUEST Component] Create requests succes'
export const CREATE_REQUESTS_FAILED = '[REQUEST Component] Create requests failed'

export const UPDATE_REQUEST = '[REQUEST Component] Update request'
export const UPDATE_REQUEST_SUCCESSFUL = '[REQUEST Component] Update request succes'
export const UPDATE_REQUEST_FAILED = '[REQUEST Component] Update request failed'

export const GET_ALL_REQUESTS = '[REQUEST Component] Get all requests'
export const GET_ALL_REQUESTS_SUCCESSFUL = '[REQUEST Component] Get all requests succes'
export const GET_ALL_REQUESTS_FAILED = '[REQUEST Component] Get all requests failed'

export const GET_REQUESTS_OF_LIO = '[REQUEST Component] Get requests of lio'
export const GET_REQUESTS_OF_LIO_SUCCESSFUL = '[REQUEST Component] Get requests of lio succes'
export const GET_REQUESTS_OF_LIO_FAILED = '[REQUEST Component] Get requests of lio failed'

export const GET_REQUEST_BY_ID = '[REQUEST Component] Get request by id'
export const GET_REQUEST_BY_ID_SUCCESSFUL = '[REQUEST Component] Get request by id succes'
export const GET_REQUEST_BY_ID_FAILED = '[REQUEST Component] Get request by id failed'

export const GET_LATEST_FIVE_PENDING_REQUESTS =
  '[REQUEST Component] Get latest five pending requests'
export const GET_LATEST_FIVE_PENDING_REQUESTS_SUCCESSFUL =
  '[REQUEST Component] Get latest five pending requests succes'
export const GET_LATEST_FIVE_PENDING_REQUESTS_FAILED =
  '[REQUEST Component] Get latest five pending requests failed'

export const GET_LATEST_FIVE_REQUESTS_BY_LIO = '[REQUEST Component] Get latest five requests'
export const GET_LATEST_FIVE_REQUESTS_BY_LIO_SUCCESSFUL =
  '[REQUEST Component] Get latest five requests succes'
export const GET_LATEST_FIVE_REQUESTS_BY_LIO_FAILED =
  '[REQUEST Component] Get latest five requests failed'

export const APPROVE_REJECT_REQUEST = '[REQUEST Component] Approve or reject pending request'
export const APPROVE_REJECT_REQUEST_SUCCESSFUL =
  '[REQUEST Component] Approve or reject pending request success'
export const APPROVE_REJECT_REQUEST_FAILED =
  '[REQUEST Component] Approve or reject pending request failed'

export const BULK_APPROVE_REJECT_REQUEST = '[REQUEST Component] Bulk approve or reject pending requests'
export const BULK_APPROVE_REJECT_REQUEST_SUCCESSFUL =
  '[REQUEST Component] Bulk approve or reject pending requests success'
export const BULK_APPROVE_REJECT_REQUEST_FAILED =
  '[REQUEST Component] Bulk approve or reject pending requests failed'

export const GET_STANDARD_ANSWERS_FOR_APPROVE_ACTION = '[REQUEST Component] Get standard answers for approve action'
export const GET_STANDARD_ANSWERS_FOR_APPROVE_ACTION_SUCCESSFUL = '[REQUEST Component] Get standard answers for approve action succes'
export const GET_STANDARD_ANSWERS_FOR_APPROVE_ACTION_FAILED = '[REQUEST Component] Get standard answers for approve action failed'

export const getAllRequests = createAction(
  GET_ALL_REQUESTS,
  props<{ tableOptions: TableFilteringSortingOptions }>()
)

export const getAllRequestsSuccessfully = createAction(
  GET_ALL_REQUESTS_SUCCESSFUL,
  props<{ paginatedResult: PaginatedResult<RequestInterface> }>()
)

export const getAllRequestsFailed = createAction(
  GET_ALL_REQUESTS_FAILED,
  props<{ error: string }>()
)

export const getRequestsOfLio = createAction(
  GET_REQUESTS_OF_LIO,
  props<{ lioNumber: number; tableOptions: TableFilteringSortingOptions }>()
)

export const getRequestsOfLioSuccessfully = createAction(
  GET_REQUESTS_OF_LIO_SUCCESSFUL,
  props<{ paginatedResult: PaginatedResult<RequestInterface> }>()
)

export const getRequestsOfLioFailed = createAction(
  GET_REQUESTS_OF_LIO_FAILED,
  props<{ error: string }>()
)

export const getRequestById = createAction(GET_REQUEST_BY_ID, props<{ id: number }>())

export const getRequestByIdSuccessfully = createAction(
  GET_REQUEST_BY_ID_SUCCESSFUL,
  props<{ request: RequestInterface }>()
)

export const getRequestByIdFailed = createAction(
  GET_REQUEST_BY_ID_FAILED,
  props<{ error: string }>()
)

export const createRequest = createAction(
  CREATE_REQUEST,
  props<{ request: CreateRequestCommand }>()
)

export const createRequestSuccessfully = createAction(
  CREATE_REQUEST_SUCCESSFUL,
  props<{ request: RequestInterface }>()
)

export const createRequestFailed = createAction(CREATE_REQUEST_FAILED, props<{ error: string }>())

export const createRequests = createAction(
  CREATE_REQUESTS,
  props<{ bulkRequests: BulkRequestsInterface }>()
)

export const createRequestsSuccessfully = createAction(
  CREATE_REQUESTS_SUCCESSFUL,
  props<{ bulkRequestsStatus: BulkRequestStatus[] }>()
)

export const createRequestsFailed = createAction(CREATE_REQUESTS_FAILED, props<{ error: string }>())

export const getLatestFivePendingRequests = createAction(
  GET_LATEST_FIVE_PENDING_REQUESTS,
  props<{ lioNumber: number | null }>()
)

export const getLatestFivePendingRequestsSuccesfuly = createAction(
  GET_LATEST_FIVE_PENDING_REQUESTS_SUCCESSFUL,
  props<{ requests: RequestInterface[] }>()
)

export const getLatestFivePendingRequestsFailed = createAction(
  GET_LATEST_FIVE_PENDING_REQUESTS_FAILED,
  props<{ error: string }>()
)

export const getLatestFiveRequestsByLio = createAction(
  GET_LATEST_FIVE_REQUESTS_BY_LIO,
  props<{ lioNumber: number | null }>()
)

export const getLatestFiveRequestsByLioSuccesfuly = createAction(
  GET_LATEST_FIVE_REQUESTS_BY_LIO_SUCCESSFUL,
  props<{ requests: RequestInterface[] }>()
)

export const getLatestFiveRequestsByLioFailed = createAction(
  GET_LATEST_FIVE_REQUESTS_BY_LIO_FAILED,
  props<{ error: string }>()
)

export const approvePendingRequest = createAction(
  APPROVE_REJECT_REQUEST,
  props<{ approvalRequestData: ApproveRequestInterface }>()
)
export const approvePendingRequestSuccessfuly = createAction(APPROVE_REJECT_REQUEST_SUCCESSFUL)

export const approvePendingRequestFailed = createAction(
  APPROVE_REJECT_REQUEST_FAILED,
  props<{ error: string }>()
)

export const bulkApprovePendingRequest = createAction(
  BULK_APPROVE_REJECT_REQUEST,
  props<{ bulkApprovalRequestData: BulkApproveRequestsInterface }>()
)

export const bulkApprovePendingRequestSuccessfuly = createAction(BULK_APPROVE_REJECT_REQUEST_SUCCESSFUL)

export const bulkApprovePendingRequestFailed = createAction(
  BULK_APPROVE_REJECT_REQUEST_FAILED,
  props<{ error: string }>()
)

export const updateRequest = createAction(
  UPDATE_REQUEST,
  props<{ request: UpdateRequestCommand }>()
)

export const updateRequestSuccessfully = createAction(UPDATE_REQUEST_SUCCESSFUL)

export const updateRequestFailed = createAction(UPDATE_REQUEST_FAILED, props<{ error: string }>())

export const getStandardApprovalAnswers = createAction(
  GET_STANDARD_ANSWERS_FOR_APPROVE_ACTION
)

export const getStandardApprovalAnswersFailed = createAction(
  GET_STANDARD_ANSWERS_FOR_APPROVE_ACTION_FAILED,
  props<{error : string}>()
)

export const getStandardApprovalAnswersSuccesfully = createAction(
  GET_STANDARD_ANSWERS_FOR_APPROVE_ACTION_SUCCESSFUL,
  props<{answers : StandardApprovalAnswer[]}>()
)
