export enum EicMarketDocumentXmlNodes {
    EIC_MARKET_DOCUMENT = "EIC_MarketDocument",
    EIC_MARKET_DOCUMENT_NAMESPACE = "urn:iec62325.351:tc57wg16:451-n:eicdocument:1:2",
    MRID = "mRID",
    REVISION_NUMBER = "revisionNumber",
    TYPE = "type",
    SENDER_MARKET_PARTICIPANT_ID = "sender_MarketParticipant.mRID",
    CODING_SCHEME_ATTR = "codingScheme",
    SENDER_MARKET_PARTICIPANT_TYPE = "sender_MarketParticipant.marketRole.type",
    RECEIVER_MARKET_PARTICIPANT_ID = "receiver_MarketParticipant.mRID",
    RECEIVER_MARKET_PARTICIPANT_TYPE = "receiver_MarketParticipant.marketRole.type",
    CREATED_DATE_TIME = "createdDateTime",

    EIC_CODE_MARKET_DOCUMENT = "EICCode_MarketDocument",
    EIC_MRID = "mRID",
    DOC_STATUS = "docStatus",
    DOC_STATUS_VALUE = "value",
    ATTRIBUTE_INSTANCE_COMPONENT = "attributeInstanceComponent.attribute",
    LONG_NAME = "long_Names.name",
    DISPLAY_NAME = "display_Names.name",
    LAST_REQUEST_DATE = "lastRequest_DateAndOrTime.date",
    DEACTIVATION_DATE = "deactivationRequested_DateAndOrTime.date",
    EIC_CONTACT_NAME = "eICContact_MarketParticipant.name",
    EIC_CONTACT_PHONE = "eICContact_MarketParticipant.phone1",
    EIC_CONTACT_PHONE_ITU = "ituPhone",
    EIC_CONTACT_EMAIL = "eICContact_MarketParticipant.electronicAddress",
    EIC_CONTACT_EMAIL_EMAIL1 = "email1",
    EIC_STREET_ADDRESS = "eICCode_MarketParticipant.streetAddress",
    STREET_DETAIL = "streetDetail",
    ADDRESS_GENERAL = "addressGeneral",
    TOWN_DETAIL = "townDetail",
    TOWN_NAME = "name",
    POSTAL_CODE = "postalCode",
    TOWN_COUNTRY = "country",
    EU_VAT_CODE = "eICCode_MarketParticipant.vATCode_Names.name",
    ACER_NUMBER = "eICCode_MarketParticipant.aCERCode_Names.name",
    PARENT_ID = "eICParent_MarketDocument.mRID",
    RESPONSIBLE_PARTY = "eICResponsible_MarketParticipant.mRID",
    DESCRIPTION = "description",
    FUNCTION_NAMES = "Function_Names",
    FUNCTION_NAMES_NAME = "name",
}
    