export enum SettingsTypes{
    eicTableHeaders = 1,
    requestTableHeaders = 2,
    lioTableHeaders = 3,
    lioRequestsTableHeaders = 4,
    allApprovedRequests = 5,
    allEicCodes = 6,
    activeEicCodes = 7,
    deactivatedCodes = 8,
    lioUsers = 9,
    tpExports = 10,
    tpExportsHistory = 11,
    functionsTableHeaders = 12,
    messagesTableHeaders = 13
}