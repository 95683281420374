<div class="p-24px bg-white border-round">
  <div class="flex justify-content-between">
    <h1 class="mb-3 h3 text-secondary">All LIOs</h1>
  </div>

  <app-table
    [values]="lios"
    [settingType]="settingType"
    selectionMode="false"
    styleClass="p-datatable-gridlines"
    tableStyleClass="table-layout-fixed border-round"
    [availableMarketsList]="availableMarketsList"
    (impersonateLio)="impersonateLio($event)"
    [totalRecords]="totalRecords"
    [firstRow]="firstRow"
    [nrOfRows]="rowsPerPage"
    (onPageChange)="onPageChange($event)"
    [defaultSortField]="defaultSortField"
  >
    <div leftSideContainer class="flex align-items-center">
      <p-button label="Add new LIO" [routerLink]="'/lio/new-lio'" icon="icon icon--plus"></p-button>
    </div>
  </app-table>
</div>
