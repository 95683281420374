import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { eicCodeTypesList } from '@app/shared/types/enums/eic-code-type.enum';
import { functionStatusList } from '@app/shared/types/enums/function-status.enum';
import { functionValidMarketList } from '@app/shared/types/enums/valid-market.enum';

@Component({
  selector: 'app-new-function',
  templateUrl: './new-function.component.html',
  styleUrls: ['./new-function.component.scss']
})
export class NewFunctionComponent {
  @Input() functionForm: FormGroup;
  @Input() isEdit: boolean = false;
  codeFunctionOptions = eicCodeTypesList;
  statusLisOption = functionStatusList;
  validMarketListOptions = functionValidMarketList

  ngOnInit(){}
}
