import { Component, Input, OnInit } from '@angular/core';
import { MessageInterface } from '@app/shared/types/message.interface';

@Component({
  selector: 'app-message-card',
  templateUrl: './message-card.component.html',
  styleUrls: ['./message-card.component.scss']
})
export class MessageCardComponent implements OnInit{
  @Input() message: MessageInterface

  ngOnInit(){}
}
