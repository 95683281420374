import { Component, ViewChild } from '@angular/core'
import {
  RequestStatusEnum,
  requestStatusFilterOptions,
  requestStatusList
} from '@app/shared/types/enums/request-status.enum'
import {
  RequestActionEnum,
  requestActionFilterOptions,
  requestActionsList
} from '@app/shared/types/enums/request-action.enum'
import { Observable } from 'rxjs/internal/Observable'
import { RequestInterface } from '@app/shared/types/request.interface'
import { Store, select } from '@ngrx/store'
import { RequestStateInterface } from '@app/shared/types/request-state.interface'
import { requestsSelector, totalRecordsSelector } from '../../../store/selectors/request.selector'
import * as RequestActions from '../../../store/actions/request.actions'
import { VisibilityColumnsHeader } from '@app/shared/types/visibility-columns-header.interface'
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum'
import { ActivatedRoute, Router } from '@angular/router'
import { TableComponent } from '@app/components/table/table.component'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { Subscription } from 'rxjs'
import { requestSourceFilterOptions, requestSourceList } from '@app/shared/types/enums/request-source.enum'
import { eicCodeTypesList } from '@app/shared/types/enums/eic-code-type.enum'
import { ModalChangeStatusComponent } from '@app/components/modal/modal-change-status/modal-change-status.component'
import { PendingRequestActionEnum } from '@app/shared/types/enums/pending-request-action.enum'
import { MessageService } from 'primeng/api'

@Component({
  selector: 'app-request-overview',
  templateUrl: './request-overview.component.html',
  styleUrls: ['./request-overview.component.scss']
})
export class RequestOverviewComponent {
  @ViewChild('modalChangeStatusComponent') modalChangeStatusComponent: ModalChangeStatusComponent
  @ViewChild('table') table!: TableComponent

  requests: RequestInterface[] = []
  requests$: Observable<RequestInterface[]>
  requestsSub: Subscription

  requestStatusList = requestStatusList
  requestActionsList = requestActionsList
  requestSourceList = requestSourceList
  requestStatusFilterOptions = requestStatusFilterOptions
  requestActionFilterOptions = requestActionFilterOptions
  requestSourceFilterOptions = requestSourceFilterOptions

  visibilityColumnsHeaderObj: VisibilityColumnsHeader = new VisibilityColumnsHeader()
  eicCodeTypesOptions = eicCodeTypesList
  
  settingType: SettingsTypes
  selectedRows: any[] = []

  pageNumber: number | undefined = 0
  rowsPerPage: number | undefined = 10

  totalRecords$: Observable<number>
  totalRecords: number
  firstRow: number = 0
  querySub: Subscription
  recordSub: Subscription

  tableFilterOrSortOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions()

  constructor(
    private store: Store<RequestStateInterface>,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.requests$ = this.store.pipe(select(requestsSelector))
    this.totalRecords$ = this.store.pipe(select(totalRecordsSelector))
  }

  ngOnInit() {
    let paramsOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions()
    this.querySub = this.route.queryParams.subscribe((params) => {
      Object.keys(params).forEach((key) => {
        if (params[key] != null) {
          switch (key) {
            case 'requestAction':
              if (params[key] != undefined) {
                paramsOptions[key] = ""
                  params[key].split(",").forEach(requestActionLabel => {
                    paramsOptions[key] += requestActionsList.find((item) => item.label == requestActionLabel)?.value + ","
                  })
                  paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
              }
              break
            case 'status':
              if (params[key] != undefined) {
                paramsOptions[key] = ""
                  params[key].split(",").forEach(requestStatusLabel => {
                    paramsOptions[key] += requestStatusList.find((item) => item.label == requestStatusLabel)?.value + ","
                  })
                  paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
              }
              break
            default:
              paramsOptions[key] = params[key]
              break
          }
        }
      })
    })
    this.pageNumber = paramsOptions.pageNumber
    this.rowsPerPage = paramsOptions.rowsPerPage

    if (!this.pageNumber || !this.rowsPerPage) {
      this.pageNumber = 0
      this.rowsPerPage = 10
    }

    this.firstRow = this.rowsPerPage * this.pageNumber
    this.rowsPerPage = parseInt(this.rowsPerPage?.toString(), 10)

    this.tableFilterOrSortOptions.pageNumber = this.pageNumber
    this.tableFilterOrSortOptions.rowsPerPage = this.rowsPerPage

    this.recordSub = this.totalRecords$.subscribe((total) => {
      this.totalRecords = total
    })

    Object.keys(paramsOptions).forEach((key) => {
      if (paramsOptions[key] != null) {
        this.tableFilterOrSortOptions[key] = paramsOptions[key]
      }
    })

    this.getData(this.tableFilterOrSortOptions)

    this.settingType = SettingsTypes.requestTableHeaders
    this.visibilityColumnsHeaderObj.optionDisabled = 'activeStatus'
  }

  onRowChange(event: any) {
    this.selectedRows = event
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {
    let props = tableFilterOrSortOptions

    Object.keys(props).forEach(function (key) {
      if (props[key] == null) {
        delete props[key]
      }
    })

    this.tableFilterOrSortOptions = props

    this.getData(props)

    this.table.selectedRows.length = 0;
    this.table.selectedRows = [];
  }

  setQueryParams() {
    let queryParams: TableFilteringSortingOptions = new TableFilteringSortingOptions()

    Object.keys(this.tableFilterOrSortOptions).forEach((key) => {
      if (this.tableFilterOrSortOptions[key] != null) {
        if (key != 'sortBy') {
          switch (key) {
            case 'requestAction':
              if (this.tableFilterOrSortOptions[key] != undefined) {
                queryParams[key] = ""
                this.tableFilterOrSortOptions[key]?.split(",").forEach(requestAction => {
                  queryParams[key] += requestActionsList[requestAction].label + ','
                })
                queryParams[key] = queryParams[key]?.slice(0, -1)
              }
              break
            case 'status':
              if (this.tableFilterOrSortOptions[key] != undefined) {
                queryParams[key] = ""
                this.tableFilterOrSortOptions[key]?.split(",").forEach(requestStatus => {
                  queryParams[key] += requestStatusList[requestStatus].label + ','
                })
                queryParams[key] = queryParams[key]?.slice(0, -1)
              }
              break
            default:
              queryParams[key] = this.tableFilterOrSortOptions[key]
              break
          }
        }
      }
    })

    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })
  }

  ngOnDestroy() {
    this.recordSub.unsubscribe()
    this.querySub.unsubscribe()
    this.requestsSub.unsubscribe()
  }

  getData(tableOptions: TableFilteringSortingOptions) {
    this.store.dispatch(RequestActions.getAllRequests({ tableOptions: tableOptions }))


    this.requestsSub = this.requests$.subscribe((data) => {
      this.requests = data.map((item) => {
        let req = Object.assign({}, item, {
          statusObj: {
            label: requestStatusList[item.status].label,
            value: item.status
          },
          requestActionObj: {
            label: requestActionsList[item.requestAction].label,
            value: item.requestAction
          },
          sourceObj: {
            label: item.source != null ? requestSourceList[item.source].label : '',
            value: item.source != null ? item.source : ''
          },
          eicCodeTypeObj: {
            label: eicCodeTypesList.filter((obj) => obj.value == item.eicCodeType)[0].label,
            value: item.eicCodeType
          },          
          itemUrl: 'cio/requests/' + item.id?.toString()
        })
        return req
      })
    })
    this.setQueryParams()
  }

  redirectToItem($event: any) {
    if ($event.data.itemUrl && !this.checkIfEventComesFromCheckboxClick($event)) {
      this.router.navigate([$event.data.itemUrl])
    }
  }

  checkIfEventComesFromCheckboxClick($event: any) {
    if (typeof($event?.originalEvent?.target?.className) === "string")
      return $event?.originalEvent?.target?.className?.includes("checkbox");
    else 
      return $event?.originalEvent?.target?.className?.baseVal.includes("checkbox");
  }

  showDialog(label: string) {
    if (this.table.selectedRows.find(request => request.status !== RequestStatusEnum.Pending)) {
      this.messageService.add( {
        key: 'toast',
        severity: 'error',
        detail: 'Approval/Rejection Failed. Please unselect the request(s) that are already approved or rejected.'
      });
    } else {
      this.modalChangeStatusComponent.showDialog({
        eicCodes: this.table.selectedRows.map(x => x.eicCode),
        requestIds: this.table.selectedRows.map(x => x.id),
        selectedAction:  PendingRequestActionEnum[label],
        title: label + ' request(s)',
        subTitle: `Do you want to ${label.toLowerCase()} following request(s):`,
        label: label
      });
      
    }
  }

  submitApproval(){
    this.table.selectedRows.length = 0;
    this.table.selectedRows = [];
  }
}

