import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, catchError, throwError } from 'rxjs'
import { RequestInterface } from '../types/request.interface'
import { ApproveRequestInterface } from '../types/approve-request.interface'
import { RequestStatusEnum } from '../types/enums/request-status.enum'
import { LatestRequestsInterface } from '../types/latest-request.interface'
import { BulkRequestsInterface } from '../types/bulk-requests.interface'
import { CreateRequestCommand } from '../types/commands/createRequestCommand.model'
import { UpdateRequestCommand } from '../types/commands/updateRequestCommand.model'
import { PaginatedResult } from '../types/paginated-result.interface'
import { TableFilteringSortingOptions } from '../types/export-options-columns.interface'
import { BulkRequestStatus } from '../types/bulk-request-status-interface'
import { BulkApproveRequestsInterface } from '../types/bulk-approve-requests.interface'
import { PendingRequestActionEnum } from '../types/enums/pending-request-action.enum'
import { StandardApprovalAnswer } from '../types/standard-approval-answer.interface'

@Injectable()
export class RequestService {
  constructor(private http: HttpClient) {}

  createRequest(request: CreateRequestCommand): Observable<RequestInterface> {
    return this.http.post<RequestInterface>('requests', request).pipe(catchError(this.handleError))
  }

  createRequests(bulkRequests: BulkRequestsInterface): Observable<BulkRequestStatus[]> {
    return this.http
      .post<BulkRequestStatus[]>('requests/bulk', bulkRequests)
      .pipe(catchError(this.handleError))
  }

  updateRequest(request: UpdateRequestCommand) {
    return this.http.put('requests', request).pipe(catchError(this.handleError))
  }

  getAllRequests(
    tableOptions: TableFilteringSortingOptions | undefined
  ): Observable<PaginatedResult<RequestInterface>> {
    return this.http
      .get<PaginatedResult<RequestInterface>>('requests/', {
        params: this.ToHttpParams(tableOptions)
      })
      .pipe(catchError(this.handleError))
  }

  getRequestsOfLio(
    lioNumber: number,
    tableOptions: TableFilteringSortingOptions | undefined
  ): Observable<PaginatedResult<RequestInterface>> {
    return this.http
      .get<PaginatedResult<RequestInterface>>('lios/' + lioNumber.toString() + '/requests', {
        params: this.ToHttpParams(tableOptions)
      })
      .pipe(catchError(this.handleError))
  }

  getRequestById(requestId: number): Observable<RequestInterface> {
    return this.http
      .get<RequestInterface>('requests/' + requestId.toString())
      .pipe(catchError(this.handleError))
  }

  getLatestFivePendingRequests(lioNumber: number | null): Observable<RequestInterface[]> {
    var latestPendingRequestsData: LatestRequestsInterface = {
      lioNumber: lioNumber,
      status: RequestStatusEnum.Pending
    }

    return this.http
      .get<RequestInterface[]>('requests/latest', {
        params: this.ToHttpParams(latestPendingRequestsData)
      })
      .pipe(catchError(this.handleError))
  }

  getLatestFiveRequests(lioNumber: number | null): Observable<RequestInterface[]> {
    var latestRequestsData: LatestRequestsInterface = {
      lioNumber: lioNumber,
      status: RequestStatusEnum.Approved
    }

    return this.http
      .get<RequestInterface[]>('requests/latest', { params: this.ToHttpParams(latestRequestsData) })
      .pipe(catchError(this.handleError))
  }

  approvePendingRequest(approvalRequestData: ApproveRequestInterface) {
    return this.http
      .post('requests/approve/', approvalRequestData)
      .pipe(catchError(this.handleError))
  }

  bulkApprovePendingRequest(bulkApproveReqeust: BulkApproveRequestsInterface) {
    return this.http
      .post('requests/bulkApprove/', bulkApproveReqeust)
      .pipe(catchError(this.handleError))
  }

  getStandardApprovalAnswersForAction(): Observable<StandardApprovalAnswer[]>{
    return this.http.get<StandardApprovalAnswer[]>('requests/standardApprovalAnswer/')
    .pipe(catchError(this.handleError))
  }

  private ToHttpParams(request: any): HttpParams {
    let httpParams = new HttpParams()
    Object.keys(request).forEach(function (key) {
      httpParams = httpParams.append(key, request[key])
    })

    return httpParams
  }

  private handleError(error: HttpErrorResponse) {
    var errorMessage = ''
    if (error.status === 0) {
      errorMessage = 'Could not connect to the backend API. Please contact the system administrator'
    } else {
      errorMessage = error.error
    }
    return throwError(() => new Error(errorMessage))
  }
}
