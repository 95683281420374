<div class="container" *ngIf="!openedMessages">
<p-messages
      [value]="firstMessages"
      [enableService]="false"
      [closable]="true"
      [showTransitionOptions]="'300ms'"
      [hideTransitionOptions]="'300ms'"
      styleClass="p-message-no-icon mb-6"
      
    >
</p-messages>
<p-messages></p-messages>
<p-button *ngIf="firstMessages.length != 0" class="more-button" label="More" styleClass="p-button-text" (onClick)="openMessages()"/>
</div>

<div class="container" *ngIf="openedMessages">
    <p-messages
      [value]="messages"
      [enableService]="false"
      [closable]="true"
      [showTransitionOptions]="'300ms'"
      [hideTransitionOptions]="'300ms'"
      styleClass="p-message-no-icon mb-6"
    >
    </p-messages>
    <p-button *ngIf="messages.length != 0" class="more-button" label="Less" styleClass="p-button-text" (onClick)="closeMessages()"/>
</div>