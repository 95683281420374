import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError, throwError } from 'rxjs'
import { MessageInterface } from '../types/message.interface'
import { TableFilteringSortingOptions } from '../types/export-options-columns.interface'
import { PaginatedResult } from '../types/paginated-result.interface'
import { CreateMessageCommand } from '../types/commands/createMesageCommand.model'
import { UpdateMessageCommand } from '../types/commands/updateMessageCommand.model'

@Injectable()
export class HomepageMessageService {
  constructor(private http: HttpClient) {}

  getMessages(): Observable<MessageInterface[]> {
    return this.http.get<MessageInterface[]>('messages').pipe(catchError(this.handleError))
  }

  getAllMessages(tableOptions: TableFilteringSortingOptions | undefined): Observable<PaginatedResult<MessageInterface>>{
    return this.http.get<PaginatedResult<MessageInterface>>('messages/all', { params: this.ToHttpParams(tableOptions) }).pipe(catchError(this.handleError))
  }

  createMessage(message: CreateMessageCommand){
    return this.http.post('messages/', message).pipe(catchError(this.handleError))
  }

  updateMessage(message: UpdateMessageCommand){
    return this.http.put('messages/',message).pipe(catchError(this.handleError))
  }

  private ToHttpParams(request: any): HttpParams {
    let httpParams = new HttpParams()
    if(request != undefined && request != null){
      Object.keys(request).forEach(function (key) {
        httpParams = httpParams.append(key, request[key])
      })
    }

    return httpParams
  }

  private handleError(error: HttpErrorResponse) {
    var errorMessage = ''
    if (error.status === 0) {
      errorMessage = 'Could not connect to the backend API. Please contact the system administrator'
    } else {
      errorMessage = error.error
    }
    return throwError(() => new Error(errorMessage))
  }
}
