import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateMessageCommand } from '@app/shared/types/commands/updateMessageCommand.model';
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { MessageStateInterface } from '@app/shared/types/message-state.interface';
import { MessageInterface } from '@app/shared/types/message.interface';
import { endDateAfterStartDateValidator } from '@app/shared/validators/endDateAfterStartDateValidator';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import * as MessageActions from '../../../../store/actions/message.action';
import * as moment from 'moment';

@Component({
  selector: 'app-update-message',
  templateUrl: './update-message.component.html',
  styleUrls: ['./update-message.component.scss']
})
export class UpdateMessageComponent {
  @Input() message: MessageInterface;
  @Input() tableFilterOrSortOptions: TableFilteringSortingOptions;
  visible = false;
  messageForm: FormGroup;
  destroyed$ = new Subject<boolean>();
  defaultStartDate: Date;
  defaultEndDate: Date | null;

  constructor(
    private formBuilder: FormBuilder,
    private messageStore: Store<MessageStateInterface>,
    private updates$: Actions,
    private messageService: MessageService
  ){
    this.messageForm = this.formBuilder.group({
      dateGroup: new FormGroup({
        startDate: new FormControl(null, Validators.required),
        endDate: new FormControl(null, null),
      }, { validators: endDateAfterStartDateValidator}),
      messageType: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.maxLength(255)
      ])),
      textMessage: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.maxLength(3000)
      ])),
      hasBeenSubmitted: new FormControl(false, null)
    })
  }

  ngOnInit(){}

  showDialog({message, tableFilterOrSortOptions}){
    this.message = message;
    this.tableFilterOrSortOptions = tableFilterOrSortOptions;

    this.visible = true;

    this.defaultStartDate = this.dateToCet(this.message.startDate)
    if(this.message.endDate != undefined)
      this.defaultEndDate = this.dateToCet(this.message.endDate)
    else
      this.defaultEndDate = null

    this.messageForm.controls['dateGroup'].get('startDate')?.setValue(this.defaultStartDate)
    this.messageForm.controls['dateGroup'].get('endDate')?.setValue(this.defaultEndDate ?? null)
    this.messageForm.controls['messageType'].setValue(this.message.messageType)
    this.messageForm.controls['textMessage'].setValue(this.message.textMessage)

    if(this.defaultStartDate < new Date()){
      this.messageForm.controls['dateGroup'].get('startDate')?.disable()
    }
    else{
      this.messageForm.controls['dateGroup'].get('startDate')?.enable()
    }
  }

  onSubmit(){
    if(this.messageForm.valid){
      const diffOffset = this.getLocalOffset()

      var startDate = new Date(new Date(this.messageForm.controls['dateGroup'].get('startDate')?.value).getTime() + diffOffset);

      var endDate = this.messageForm.controls['dateGroup'].get('endDate')?.value ? 
      new Date(new Date(this.messageForm.controls['dateGroup'].get('endDate')?.value).getTime() + diffOffset) : undefined;

      var updateMessage : UpdateMessageCommand = {
        id: this.message.id,
        startDate: startDate,
        endDate: endDate,
        messageType: this.messageForm.controls['messageType'].value,
        textMessage: this.messageForm.controls['textMessage'].value
      }

      this.messageStore.dispatch(MessageActions.updateMessage({message: updateMessage}))

      this.updates$
        .pipe(ofType(MessageActions.UPDATE_MESSAGE_SUCCESSFUL), takeUntil(this.destroyed$))
        .subscribe(() => {
          this.messageStore.dispatch(
            MessageActions.getAllMessages({
              tableOptions: this.tableFilterOrSortOptions
            })
          )
          this.visible = false
          this.messageForm.reset()
        }
      )
    }
    else{
      this.messageForm.controls['hasBeenSubmitted'].setValue(true)
      this.messageForm.updateValueAndValidity()
      this.messageService.add({
        key: 'toast',
        severity: 'error',
        summary: 'Please fill all required fields in',
        detail: 'Check the fields for details'
      })
    }
  }

  onClose() {
    this.messageForm.reset()
    this.visible = false
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  dateToCet(date: Date){
    const offset =  moment['tz'](moment(new Date()), 'Europe/Berlin').utcOffset() * 60000;
    var berlinDateTime = new Date(new Date(date).getTime() + offset);

    return berlinDateTime;
  }

  getLocalOffset(){
    const localOffset = new Date().getTimezoneOffset() * 60000;
    const cetOffset =  moment['tz'](moment(new Date()), 'Europe/Berlin').utcOffset() * 60000;
    const diffOffset = - cetOffset - localOffset;

    return diffOffset;
  }
}
