import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum';
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { MessageStateInterface } from '@app/shared/types/message-state.interface';
import { MessageInterface } from '@app/shared/types/message.interface';
import { VisibilityColumnsHeader } from '@app/shared/types/visibility-columns-header.interface';
import { messagesSelector } from '@app/store/selectors/message.selector';
import { totalRecordsSelector } from '@app/store/selectors/message.selector';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as MessageActions from '@app/store/actions/message.action'
import { CreateMessageComponent } from '../create-message/create-message.component';
import { UpdateMessageComponent } from '../update-message/update-message.component';

@Component({
  selector: 'app-messages-overview',
  templateUrl: './messages-overview.component.html',
  styleUrls: ['./messages-overview.component.scss']
})
export class MessagesOverviewComponent {
  @ViewChild('modalCreateMessage') modalCreateMessage : CreateMessageComponent
  @ViewChild('modalUpdateMessage') modalUpdateMessage : UpdateMessageComponent

  messages$: Observable<MessageInterface[]>
  messages: MessageInterface[]
  messagesSub: Subscription

  settingType: number
  
  pageNumber: number | undefined = 0
  rowsPerPage: number | undefined = 10

  totalRecords$: Observable<number>
  totalRecords: number
  firstRow: number = 0
  querySub: Subscription
  recordSub: Subscription

  tableFilterOrSortOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions()
  visibilityColumnsHeaderObj: VisibilityColumnsHeader = new VisibilityColumnsHeader()

  constructor(
    private store: Store<MessageStateInterface>,
    private router: Router,
    private route: ActivatedRoute
  ){
    this.messages$ = this.store.pipe(select(messagesSelector))
    this.totalRecords$ = this.store.pipe(select(totalRecordsSelector))
  }

  ngOnInit(): void {
		let paramsOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions();
    this.querySub = this.route.queryParams
      .subscribe(params => {
        Object.keys(params).forEach(key => {
          if(params[key] != null){
            paramsOptions[key] = params[key]
        }
        })
      })

    this.pageNumber = paramsOptions.pageNumber
    this.rowsPerPage = paramsOptions.rowsPerPage

    if (!this.pageNumber || !this.rowsPerPage) {
      this.pageNumber = 0;
      this.rowsPerPage = 10;
    }

    this.firstRow = this.rowsPerPage * this.pageNumber
    this.rowsPerPage = parseInt(this.rowsPerPage?.toString(), 10)
    
    this.tableFilterOrSortOptions.pageNumber = this.pageNumber
    this.tableFilterOrSortOptions.rowsPerPage = this.rowsPerPage

    this.recordSub = this.totalRecords$.subscribe((total) => {
      this.totalRecords = total
    })

		Object.keys(paramsOptions).forEach(key => {
			if(paramsOptions[key] != null){
			  this.tableFilterOrSortOptions[key] = paramsOptions[key]
			}
    })

    this.getData(this.tableFilterOrSortOptions)

    this.settingType = SettingsTypes.messagesTableHeaders
    this.visibilityColumnsHeaderObj.optionDisabled = 'activeStatus'
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {
    this.pageNumber = tableFilterOrSortOptions.pageNumber
    this.rowsPerPage = tableFilterOrSortOptions.rowsPerPage
    let props = tableFilterOrSortOptions;
    	Object.keys(props).forEach(function (key) {
        	if(props[key] == null){
          delete props[key];
        	}
    	});
		this.tableFilterOrSortOptions = props

		this.getData(props)
	}

  setQueryParams() {
		let queryParams : TableFilteringSortingOptions = new TableFilteringSortingOptions()

      Object.keys(this.tableFilterOrSortOptions).forEach(key => {
        if(this.tableFilterOrSortOptions[key] != null){
          if(key != "sortBy"){
            queryParams[key] = this.tableFilterOrSortOptions[key]
          }
        }
    });

    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })
  }

  getData(tableOptions: TableFilteringSortingOptions) {
    this.store.dispatch(MessageActions.getAllMessages({tableOptions : tableOptions}))
    this.messagesSub = this.messages$.subscribe((data) => {
      this.messages = [...data]
    })
    this.setQueryParams()
    
  }

  openCreateMessageModal(){
    this.modalCreateMessage.showDialog(this.tableFilterOrSortOptions)
  }

  openUpdateMessageModal(message: MessageInterface){
    this.modalUpdateMessage.showDialog({
      message: message,
      tableFilterOrSortOptions: this.tableFilterOrSortOptions
    })
  }


  ngOnDestroy(){
    this.messagesSub?.unsubscribe();
    this.store.dispatch(MessageActions.clearMessages())
  }
}
