<div class="p-24px bg-white border-round">
  <div class="flex justify-content-between">
    <h1 class="mb-3 h3 text-secondary">All requests</h1>
  </div>

  <app-modal-change-status [isBulk]="true" [tableFilterSortingOptions]="tableFilterOrSortOptions" (onSubmitApproval)="submitApproval()" #modalChangeStatusComponent />

  <app-table
      #table
      [values]="requests"
      [visibilityColumnsHeader]="true"
      [visibilityColumnsHeaderObj]="visibilityColumnsHeaderObj"        
      [settingType]="settingType" 
      [checkBoxMultiSelect]="true"      
      [requestActionsList]="requestActionsList"
      [statusList]="requestStatusList"
      [requestSourceList]="requestSourceList"
      [requestActionFilterOptions]="requestActionFilterOptions"
      [statusFilterOptions]="requestStatusFilterOptions"
      [requestSourceFilterOptions]="requestSourceFilterOptions"
      [eicCodeTypesOptions]="eicCodeTypesOptions"
      [selectionMode]="'multiple'"
      [totalRecords]="totalRecords"
      [firstRow]="firstRow"
      [nrOfRows]="rowsPerPage"
      (onRowChange)="onRowChange($event)"
      (onRowSelect)="redirectToItem($event)"
      (onPageChange)="onPageChange($event)"
      [filterOptions]="tableFilterOrSortOptions"
      [enableSelectAll]="true"
      [selectedRows]="selectedRows"
  >
    <div handleRowSelectionButtons class="flex align-items-center">
      <p-button
        label="Reject"
        icon="icon icon--reject"
        styleClass="p-button-danger mr-3"
        [disabled]="selectedRows.length === 0"
        (click)="showDialog('Reject')"
      />
      <p-button
        label="Approve"
        icon="icon icon--checkmark2"
        styleClass="p-button- mr-3"
        [disabled]="selectedRows.length === 0"
        (click)="showDialog('Approve')"
      />
    </div>
  </app-table>
</div>
