import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActiveEicCodesInterface } from '@app/shared/types/active-eic-codes.interface'
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum'
import { ReportInterface } from '@app/shared/types/report.interface'
import { getActiveEIC, totalRecordsSelector } from '@app/store/selectors/report.selector'
import { Store } from '@ngrx/store'
import * as ReportActions from '../../../store/actions/report.actions'
import { Observable, Subscription } from 'rxjs'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { ExportPlace } from '@app/shared/types/enums/export-place.enum'
import { ExportTypeCodes } from '@app/shared/types/enums/export-type-codes.enum'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-active-eic-codes',
  templateUrl: './active-eic-codes.component.html',
  styleUrls: ['./active-eic-codes.component.scss']
})
export class ActiveEicCodesComponent implements OnInit {
  today: number = Date.now()
  settingType: number
  eicCodes: ActiveEicCodesInterface[]
  report$: Observable<ActiveEicCodesInterface[]>
  reportsSub: Subscription

  pageNumber: number | undefined = 0
  rowsPerPage: number | undefined = 10

  totalRecords$: Observable<number>
  totalRecords: number
  firstRow: number = 0
  recordSub: Subscription
  querySub: Subscription

  exportOptionColumns: TableFilteringSortingOptions = new TableFilteringSortingOptions()
  exportPlace: ExportPlace = ExportPlace.ActiveEIC

  exportOptions: any[]

  constructor(private reportStore: Store<ReportInterface>,
    private route: ActivatedRoute,
    private router: Router) {
    this.report$ = this.reportStore.select(getActiveEIC)
    this.totalRecords$ = this.reportStore.select(totalRecordsSelector)
  }

  ngOnInit(): void {
    this.settingType = SettingsTypes.activeEicCodes
    this.exportOptions = [ExportTypeCodes.XML, ExportTypeCodes.CSV, ExportTypeCodes.EXCEL]

    let paramsOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions();

    this.querySub = this.route.queryParams
      .subscribe(params => {
        Object.keys(params).forEach(key => {
          paramsOptions[key] = params[key]
        })
      })
      this.pageNumber = paramsOptions.pageNumber
      this.rowsPerPage = paramsOptions.rowsPerPage
      
    if (!this.pageNumber || !this.rowsPerPage) {
      this.pageNumber = 0
      this.rowsPerPage = 10
    }

    this.firstRow = this.rowsPerPage * this.pageNumber
    this.rowsPerPage = parseInt(this.rowsPerPage?.toString(), 10)

    this.recordSub = this.totalRecords$.subscribe((total) => {
      this.totalRecords = total
    })

    this.exportOptionColumns.pageNumber = this.pageNumber
    this.exportOptionColumns.rowsPerPage = this.rowsPerPage

    Object.keys(paramsOptions).forEach(key => {
      if(paramsOptions[key] != null){
        this.exportOptionColumns[key] = paramsOptions[key]
      }
    })

    this.getData(this.exportOptionColumns)
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {
    let props = tableFilterOrSortOptions
    Object.keys(props).forEach(function (key) {
      if (props[key] == null || props[key] == undefined) {
        delete props[key]
      }
    })
    this.exportOptionColumns = props

    this.getData(props)
  }

  getData(tableOptions: TableFilteringSortingOptions) {
    this.reportStore.dispatch(ReportActions.getActiveEicReport({ props: tableOptions }))

    this.reportsSub = this.report$.subscribe((data: any) => {
      this.eicCodes = [...data]
    })
    this.setQueryParams()
  }

  setQueryParams() {
    let queryParams : TableFilteringSortingOptions = new TableFilteringSortingOptions()

      Object.keys(this.exportOptionColumns).forEach(key => {
        if(this.exportOptionColumns[key] != null){
          if(key != "sortBy"){
          queryParams[key] = this.exportOptionColumns[key]
        }
      }
    });

    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })
  }

  ngOnDestroy() {
    this.recordSub.unsubscribe()
    this.reportsSub.unsubscribe()
    this.querySub.unsubscribe()
  }
}
