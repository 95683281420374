<form [formGroup]="messageForm">
    <p-fieldset styleClass="mb-5">
        <div class="form-container">
            <div class="form-date-group" formGroupName="dateGroup">
                <div class="form-item">
                    <label>Start Date <span class="text-required">*</span></label>
                    <p-calendar
                        formControlName="startDate" placeholder="Select start date" icon="icon icon--calendar" iconAriaLabel="Show calendar" 
                        readonlyInput="true" [minDate]="minDate" [showIcon]="true" [showTime]="true" (onSelect)="onClose($event, true)"
                        [ngClass]="{'ng-invalid ng-dirty': !messageForm.controls['dateGroup'].get('startDate')?.disabled && 
                        messageForm.controls['hasBeenSubmitted'].value && !messageForm.controls['dateGroup'].get('startDate')?.valid }"
                    ></p-calendar>
                    <span *ngIf="!messageForm.controls['dateGroup'].get('startDate')?.disabled && messageForm.controls['hasBeenSubmitted'].value && !messageForm.controls['dateGroup'].get('startDate')?.valid" class="p-error"> 
                        The start date must be filled in. 
                    </span>
                    
                </div>
                <div class="form-item">
                    <label>End Date</label>
                    <p-calendar 
                        formControlName="endDate" placeholder="Select end date" icon="icon icon--calendar" iconAriaLabel="Show calendar" 
                        readonlyInput="true" [minDate]="minDate" [showIcon]="true" [showTime]="true" (onSelect)="onClose($event, false)"
                        [ngClass]="{'ng-invalid ng-dirty': messageForm.controls['hasBeenSubmitted'].value && messageForm.controls['dateGroup'].errors?.['endDateBeforeStartDate'] }"
                    ></p-calendar>
                    <span *ngIf="messageForm.controls['hasBeenSubmitted'].value && messageForm.controls['dateGroup'].errors?.['endDateBeforeStartDate']" class="p-error"> 
                        End Date cannot be before Start Date.
                    </span>
                </div>
            </div>
            <div class="form-item">
                <label>Message type <span class="text-required">*</span></label>
                <textarea class="w-full" pInputTextarea placeholder="Leave a note"
                    formControlName="messageType" [ngClass]="{'err' : messageForm.controls['hasBeenSubmitted'].value &&
                        !messageForm.controls['messageType'].valid}"></textarea>
                <span *ngIf="messageForm.controls['hasBeenSubmitted'].value && messageForm.controls['messageType'].errors?.['required']" class="p-error"> 
                    The message type must be filled in. 
                </span>
                <span *ngIf="messageForm.controls['messageType'].errors?.['maxlength']" class="p-error"> 
                    The message type must contain a maximum of 255 characters.
                </span>                
            </div>
            <div class="form-item">
                <label>Message <span class="text-required">*</span></label>
                <textarea class="w-full" pInputTextarea placeholder="Leave a note"
                    formControlName="textMessage" [ngClass]="{'err' : messageForm.controls['hasBeenSubmitted'].value &&
                        !messageForm.controls['textMessage'].valid}"></textarea>
                <span *ngIf="messageForm.controls['hasBeenSubmitted'].value && messageForm.controls['textMessage'].errors?.['required']" class="p-error"> 
                    The message must be filled in. 
                </span>
                <span *ngIf="messageForm.controls['textMessage'].errors?.['maxlength']" class="p-error"> 
                    The message must contain a maximum of 3000 characters.
                </span> 
            </div>
        </div>
    </p-fieldset>
</form>