import { Component, OnInit } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { LioStateInterface } from '../../../shared/types/lio-state.interface'
import { LioInterface } from '../../../shared/types/lio.interface'
import { Store, select } from '@ngrx/store'
import * as LioActions from '@app/store/actions/lio.actions'
import { liosSelector, totalRecordsSelector } from '@app/store/selectors/lio.selector'
import { availableMarketsList } from '@app/shared/types/enums/available-markets.enum'
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum'
import { VisibilityColumnsHeader } from '@app/shared/types/visibility-columns-header.interface'
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface'
import { UserInfoInterface } from '@app/shared/types/user-info.interface'
import { currentUserSelector } from '@app/store/selectors/user-info.selector'

import * as UserActions from '../../../store/actions/user-info.actions'
import { ActivatedRoute, Router } from '@angular/router'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'

@Component({
  selector: 'app-lio-overview',
  templateUrl: './lio-overview.component.html',
  styleUrls: ['./lio-overview.component.scss']
})
export class LioOverviewComponent implements OnInit {
  availableMarketsList = availableMarketsList

  lios$: Observable<LioInterface[]>
  lios: LioInterface[]
  liosSub: Subscription

  settingType: number
  visibilityColumnsHeaderObj: VisibilityColumnsHeader = new VisibilityColumnsHeader()

  currentUser$: Observable<UserInfoInterface>
  isImpersonating: boolean
  userSub: Subscription

  pageNumber: number | undefined = 0
  rowsPerPage: number | undefined = 10

  totalRecords$: Observable<number>
  totalRecords: number
  firstRow: number = 0
  querySub: Subscription
  recordSub: Subscription
  defaultSortField: any = {field: "lioNumber", direction: 1}

  tableFilterOrSortOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions()

  constructor(
    private store: Store<LioStateInterface>,
    private userStore: Store<UserInfoStateInterface>,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.lios$ = this.store.pipe(select(liosSelector))
    this.currentUser$ = this.userStore.pipe(select(currentUserSelector))

    this.userSub = this.currentUser$.subscribe((value: UserInfoInterface) => {
      this.isImpersonating = value.isImpersonating!
    })
    this.totalRecords$ = this.store.pipe(select(totalRecordsSelector))
  }

	ngOnInit(): void {
		let paramsOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions();
    this.querySub = this.route.queryParams
      .subscribe(params => {
        Object.keys(params).forEach(key => {
          if(params[key] != null){

            switch(key){
              case "availableMarketsList":
                if(params[key] != undefined){
                  paramsOptions[key] = availableMarketsList.find(item => item.label == params[key])?.value
                }
                break;
              default:
                paramsOptions[key] = params[key]
                break;
          }
        }
        })
      })
      this.pageNumber = paramsOptions.pageNumber
      this.rowsPerPage = paramsOptions.rowsPerPage
		if (!this.pageNumber || !this.rowsPerPage) {
			this.pageNumber = 0;
			this.rowsPerPage = 10;
		}

    this.firstRow = this.rowsPerPage * this.pageNumber
    this.rowsPerPage = parseInt(this.rowsPerPage?.toString(), 10)
    
    this.tableFilterOrSortOptions.pageNumber = this.pageNumber
    this.tableFilterOrSortOptions.rowsPerPage = this.rowsPerPage

    this.recordSub = this.totalRecords$.subscribe((total) => {
      this.totalRecords = total
		})

		Object.keys(paramsOptions).forEach(key => {
			if(paramsOptions[key] != null){
			  this.tableFilterOrSortOptions[key] = paramsOptions[key]
			}
      })

    this.getData(this.tableFilterOrSortOptions)

    this.settingType = SettingsTypes.lioTableHeaders
    this.visibilityColumnsHeaderObj.optionDisabled = 'activeStatus'
  }

  impersonateLio(event: any) {
    this.store.dispatch(UserActions.impersonateLio({ lioNumber: event.lioNumber }))
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {
    this.pageNumber = tableFilterOrSortOptions.pageNumber
    this.rowsPerPage = tableFilterOrSortOptions.rowsPerPage
    let props = tableFilterOrSortOptions;
    	Object.keys(props).forEach(function (key) {
        	if(props[key] == null){
          delete props[key];
        	}
    	});
		this.tableFilterOrSortOptions = props

		this.getData(props)
	}

	setQueryParams() {
		let queryParams : TableFilteringSortingOptions = new TableFilteringSortingOptions()

      Object.keys(this.tableFilterOrSortOptions).forEach(key => {
        if(this.tableFilterOrSortOptions[key] != null){
          if(key != "sortBy"){
            
            switch(key){
              case "availableMarketsList":
                if(this.tableFilterOrSortOptions[key] != undefined){
                  queryParams[key] = availableMarketsList[this.tableFilterOrSortOptions[key] ?? ''].label
                }
                break;
              default:
                queryParams[key] = this.tableFilterOrSortOptions[key]
                break;
            }
          }
        }
    });

    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })
  }

  ngOnDestroy() {
    this.querySub.unsubscribe()
    this.recordSub.unsubscribe()
    this.liosSub.unsubscribe()
    this.userSub.unsubscribe()
  }

  getData(tableOptions: TableFilteringSortingOptions) {
    this.store.dispatch(LioActions.getLios({ tableOptions: tableOptions }))
    this.liosSub = this.lios$.subscribe((data) => {
      this.lios = [...data]
    })
    this.setQueryParams()
  }
}
