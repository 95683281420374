<form [formGroup]="functionForm">
    <p-fieldset styleClass="mb-5">
        <div class="form-container">
            <div class="form-item">
                <label>Function Type <span class="text-required">*</span></label>
                <p-dropdown
                  class="eicTypeDropdown"
                  placeholder="Select"
                  dropdownIcon="icon icon--angle-down"
                  [options]="codeFunctionOptions"
                  formControlName="functionCode"
                  [ngClass]="{'err': functionForm.controls['hasBeenSubmitted'].value && 
                  !functionForm.controls['functionCode']?.valid}"
                  styleClass="mt-0"
                ></p-dropdown>
                <span *ngIf="!functionForm.controls['functionCode'].disabled &&
                  !functionForm.controls['functionCode']?.valid && 
                  functionForm.controls['hasBeenSubmitted'].value" 
                  class="p-error"> 
                  A code type must be chosen. 
                </span>
            </div>
            <div class="form-item">
              <label>Function Name <span class="text-required">*</span></label>
              <input class="w-full" formControlName="functionName"
                [ngClass]="{'err': functionForm.controls['hasBeenSubmitted'].value && 
                !functionForm.controls['functionName']?.valid}" placeholder="Input" pInputText />
                <span *ngIf="functionForm.controls['functionName']?.errors?.['pattern']" class="p-error"> 
                  Each word in the name must start with a capital letter and no numbers or special characters are allowed 
                </span>
                <span *ngIf="functionForm.controls['functionName'].errors?.['maxlength']" class="p-error"> 
                  The name of the function must contain a maximum of 70 characters.
                </span>  
            </div>
            <div class="form-item">
              <label>Valid in Market <span class="text-required">*</span></label>
                <p-dropdown
                  placeholder="Select"
                  dropdownIcon="icon icon--angle-down"
                  [options]="validMarketListOptions"
                  formControlName="functionValidMarket"
                  [ngClass]="{'err': functionForm.controls['hasBeenSubmitted'].value && 
                  !functionForm.controls['functionValidMarket']?.valid}"
                  styleClass="mt-0"
                ></p-dropdown>
                <span *ngIf="!functionForm.controls['functionValidMarket'].valid && 
                  functionForm.controls['hasBeenSubmitted'].value" 
                  class="p-error"> 
                  A valid market must be chosen. 
                </span>
            </div>
            <div *ngIf="isEdit" class="form-item">
              <label>Status <span class="text-required">*</span></label>
                <p-dropdown
                  placeholder="Select"
                  dropdownIcon="icon icon--angle-down"
                  [options]="statusLisOption"
                  formControlName="functionStatus"
                  [ngClass]="{'err': functionForm.controls['hasBeenSubmitted'].value && 
                  !functionForm.controls['functionStatus']?.valid}"
                  styleClass="mt-0"
                ></p-dropdown>
                <span *ngIf="!functionForm.controls['functionStatus'].valid && 
                  functionForm.controls['hasBeenSubmitted'].value" 
                  class="p-error"> 
                  A status must be chosen. 
                </span>
            </div>
            <div class="form-item">
                <label>Definition</label>
                <textarea
                  class="w-full"
                  pInputTextarea
                  placeholder="Leave a note"
                  formControlName="definition"
                ></textarea>
                <span *ngIf="functionForm.controls['definition'].errors?.['maxlength']" class="p-error"> 
                  The definition must contain a maximum of 3000 characters.
                </span>  
            </div>
        </div>
      </p-fieldset>
</form>