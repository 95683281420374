import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { MessageStateInterface } from '@app/shared/types/message-state.interface';
import { endDateAfterStartDateValidator } from '@app/shared/validators/endDateAfterStartDateValidator';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import * as MessageActions from '../../../../store/actions/message.action';
import { CreateMessageCommand } from '@app/shared/types/commands/createMesageCommand.model';

@Component({
  selector: 'app-create-message',
  templateUrl: './create-message.component.html',
  styleUrls: ['./create-message.component.scss']
})
export class CreateMessageComponent implements OnInit {
  @Input() tableFilterOrSortOptions: TableFilteringSortingOptions;
  visible = false;
  messageForm: FormGroup;
  destroyed$ = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private messageStore: Store<MessageStateInterface>,
    private updates$: Actions,
    private messageService: MessageService
  ){
    this.messageForm = this.formBuilder.group({
      dateGroup: new FormGroup({
        startDate: new FormControl(null, Validators.required),
        endDate: new FormControl(null, null),
      }, { validators: endDateAfterStartDateValidator}),
      messageType: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.maxLength(255)
      ])),
      textMessage: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.maxLength(3000)
      ])),
      hasBeenSubmitted: new FormControl(false, null)
    })
  }

  ngOnInit(){}

  showDialog(tableFilterOrSortOptions : TableFilteringSortingOptions){
    this.tableFilterOrSortOptions = tableFilterOrSortOptions;
    this.visible = true;
  }

  onSubmit(){
    if(this.messageForm.valid){
      var newMessage : CreateMessageCommand = {
        startDate : this.messageForm.controls['dateGroup'].get('startDate')?.value,
        endDate : this.messageForm.controls['dateGroup'].get('endDate')?.value,
        messageType : this.messageForm.controls['messageType'].value,
        textMessage: this.messageForm.controls['textMessage'].value
      }

      this.messageStore.dispatch(MessageActions.createMessage({message: newMessage}))

      this.updates$
        .pipe(ofType(MessageActions.CREATE_MESSAGE_SUCCESSFUL), takeUntil(this.destroyed$))
        .subscribe(() => {
          this.messageStore.dispatch(
            MessageActions.getAllMessages({
              tableOptions: this.tableFilterOrSortOptions
            })
          )
          this.visible = false
          this.messageForm.reset()
        }
      )
    }
    else{
      this.messageForm.controls['hasBeenSubmitted'].setValue(true)
      this.messageForm.updateValueAndValidity()
      this.messageService.add({
        key: 'toast',
        severity: 'error',
        summary: 'Please fill all required fields in',
        detail: 'Check the fields for details'
      })
    }
    
  }

  onClose() {
    this.messageForm.reset()
    this.visible = false
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
