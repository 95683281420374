import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum';
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { FunctionInterface } from '@app/shared/types/function.interface';
import { FunctionStateInterface } from '@app/shared/types/function.state.interface';
import { VisibilityColumnsHeader } from '@app/shared/types/visibility-columns-header.interface';
import { functionsSelector, totalRecordsSelector } from '@app/store/selectors/function.selector';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as FunctionActions from '@app/store/actions/function.actions'
import {
  functionStatusList,
  functionStatusFilterOptions
} from '@app-shared-types/enums/function-status.enum'
import { eicCodeTypesList } from '@app/shared/types/enums/eic-code-type.enum';
import { CreateFunctionComponent } from '../create-function/create-function.component';
import { UpdateFunctionComponent } from '../update-function/update-function.component';
import { functionValidMarketFilterOptions, functionValidMarketList } from '@app/shared/types/enums/valid-market.enum';
import * as functionActions from '@app-store/actions/function.actions'

@Component({
  selector: 'app-functions-overview',
  templateUrl: './functions-overview.component.html',
  styleUrls: ['./functions-overview.component.scss']
})
export class FunctionsOverviewComponent {
  @ViewChild('modalCreateFunction') modalCreateFunction: CreateFunctionComponent
  @ViewChild('modalUpdateFunction') modalUpdateFunction: UpdateFunctionComponent

  functions$: Observable<FunctionInterface[]>
  functions: FunctionInterface[]
  functionSub: Subscription

  settingType: number
  
  pageNumber: number | undefined = 0
  rowsPerPage: number | undefined = 10

  totalRecords$: Observable<number>
  totalRecords: number
  firstRow: number = 0
  querySub: Subscription
  recordSub: Subscription

  functionStatusList = functionStatusList
  functionStatusFilterOptions = functionStatusFilterOptions
  eicCodeTypesOptions = eicCodeTypesList
  functionValidMarketFilterOptions = functionValidMarketFilterOptions
  functionValidMarketList = functionValidMarketList

  tableFilterOrSortOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions()
  visibilityColumnsHeaderObj: VisibilityColumnsHeader = new VisibilityColumnsHeader()

  constructor(
    private functionStore: Store<FunctionStateInterface>,
    private router: Router,
    private route: ActivatedRoute
  ){
    this.functions$ = this.functionStore.pipe(select(functionsSelector))
    this.totalRecords$ = this.functionStore.pipe(select(totalRecordsSelector))
  }

  ngOnInit(): void {
		let paramsOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions();
    this.querySub = this.route.queryParams
      .subscribe(params => {
        Object.keys(params).forEach(key => {
          if(params[key] != null){
            switch (key) {
              case 'functionStatus':
                if (params[key] != undefined) {
                  paramsOptions[key] = ""
                    params[key].split(",").forEach(funcStatusLabel => {
                      paramsOptions[key] += functionStatusList.find((item) => item.label == funcStatusLabel)?.value + ','
                    })
                    paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
                }
                break
              case 'functionValidMarket':
                if (params[key] != undefined) {
                  paramsOptions[key] = functionValidMarketList.find(item => item.icon == params[key])?.value
                }
                break
              default:
                paramsOptions[key] = params[key]
                break
            }
        }
        })
      })
    this.pageNumber = paramsOptions.pageNumber
    this.rowsPerPage = paramsOptions.rowsPerPage

		if (!this.pageNumber || !this.rowsPerPage) {
			this.pageNumber = 0;
			this.rowsPerPage = 10;
		}

    this.firstRow = this.rowsPerPage * this.pageNumber
    this.rowsPerPage = parseInt(this.rowsPerPage?.toString(), 10)
    
    this.tableFilterOrSortOptions.pageNumber = this.pageNumber
    this.tableFilterOrSortOptions.rowsPerPage = this.rowsPerPage

    this.recordSub = this.totalRecords$.subscribe((total) => {
      this.totalRecords = total
		})

		Object.keys(paramsOptions).forEach(key => {
			if(paramsOptions[key] != null){
			  this.tableFilterOrSortOptions[key] = paramsOptions[key]
			}
    })

    this.getData(this.tableFilterOrSortOptions)

    this.settingType = SettingsTypes.functionsTableHeaders
    this.visibilityColumnsHeaderObj.optionDisabled = 'activeStatus'
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {
    this.pageNumber = tableFilterOrSortOptions.pageNumber
    this.rowsPerPage = tableFilterOrSortOptions.rowsPerPage
    let props = tableFilterOrSortOptions;
    	Object.keys(props).forEach(function (key) {
        	if(props[key] == null){
          delete props[key];
        	}
    	});
		this.tableFilterOrSortOptions = props

		this.getData(props)
	}

  setQueryParams() {
		let queryParams : TableFilteringSortingOptions = new TableFilteringSortingOptions()

      Object.keys(this.tableFilterOrSortOptions).forEach(key => {
        if(this.tableFilterOrSortOptions[key] != null){
          if(key != "sortBy"){
            switch (key) {
              case 'functionStatus':
                if (this.tableFilterOrSortOptions[key] != undefined) {
                  queryParams[key] = ""
                  this.tableFilterOrSortOptions[key]?.split(",").forEach(funcStatus => {
                    queryParams[key] += functionStatusList[funcStatus].label + ','
                  })
                  queryParams[key] = queryParams[key]?.slice(0, -1)
                }
                break
              case 'functionValidMarket':
                if (this.tableFilterOrSortOptions[key] != undefined) {
                  queryParams[key] = functionValidMarketList[this.tableFilterOrSortOptions[key] ?? ''].icon
                }
                break
              default:
                queryParams[key] = this.tableFilterOrSortOptions[key]
                break
            }
          }
        }
    });

    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })
  }

  getData(tableOptions: TableFilteringSortingOptions) {
    this.functionStore.dispatch(FunctionActions.getFunctions({tableOptions : tableOptions}))
    this.functionSub = this.functions$.subscribe((data) => {
      this.functions = data.map((item) =>{
        let func = Object.assign({}, item, {
          functionStatusObj: {
            label: functionStatusList[item.status].label,
            value: item.status
          },
          eicCodeTypeObj: {
            label: eicCodeTypesList.filter((obj) => obj.value == item.code)[0].label,
            value: item.code
          },
          functionValidMarketObj: {
            label: item.validMarket ? functionValidMarketList[item.validMarket].label : null,
            value: item.validMarket
          }
        })
        return func
      })
    })
    this.setQueryParams()
  }

  ngOnDestroy() {
    this.querySub.unsubscribe()
    this.recordSub.unsubscribe()
    this.functionSub.unsubscribe()
    
    this.functionStore.dispatch(functionActions.getFunctions({tableOptions: undefined}));
  }

  OpenUpdateFunctionModal(func: FunctionInterface){
    this.modalUpdateFunction.showDialog({
      func: func,
      tableFilterOrSortOptions: this.tableFilterOrSortOptions
    })
  }

  OpenCreateFunctionModal(){
    this.modalCreateFunction.showDialog(this.tableFilterOrSortOptions)
  }
}
