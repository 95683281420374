<div class="p-24px bg-white border-round">
    <div class="flex justify-content-between mb-6">
      <h1 class="mb-3 h3 text-secondary">News and Release Management</h1>
    </div>

    <app-create-message #modalCreateMessage></app-create-message>
    <app-update-message #modalUpdateMessage></app-update-message>

    <app-table
    [values]="messages"
    [settingType]="settingType"
    selectionMode="false"
    styleClass="p-datatable-gridlines"
    tableStyleClass="table-layout-fixed border-round"
    [totalRecords]="totalRecords"
    [firstRow]="firstRow"
    [nrOfRows]="rowsPerPage"
    (onPageChange)="onPageChange($event)"
    (onMessageEdit)="openUpdateMessageModal($event)"
    [filterOptions]="tableFilterOrSortOptions"
    [expandableRows]="true"
  >
    <div leftSideContainer class="flex align-items-center">
      <p-button label="Add new message" icon="icon icon--plus" (onClick)="openCreateMessageModal()"></p-button>
    </div>
  </app-table>
  </div>
