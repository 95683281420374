import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const endDateAfterStartDateValidator: ValidatorFn = (control: AbstractControl) : ValidationErrors | null =>{
    var endDate = control.get('endDate')?.value
    var startDate = control.get('startDate')?.value

    if(startDate != null && endDate != null){
        var validatorResponse = ((new Date(endDate)).getTime() > (new Date(startDate).getTime())) ? null : {endDateBeforeStartDate : true}
        return validatorResponse
    }
    else{
        return null
    }
}