export class LioDefaultTableHeaders {
  defaultColumns = [
    {
      field: 'lioNumber',
      header: 'ID LIO',
      visible: true,
      activeStatus: true,
      sort: true,
      filter: true,
      minWidth: 100,
      isDate: false
    },
    {
      field: 'name',
      header: 'Name LIO',
      visible: true,
      activeStatus: true,
      sort: true,
      filter: true,
      minWidth: 150,
      isDate: false
    },
    {
      field: 'contactPersonName',
      header: 'Contact Person',
      visible: true,
      activeStatus: true,
      sort: false,
      filter: true,
      minWidth: 150,
      isDate: false
    },
    {
      field: 'contactPersonEmail',
      header: 'Contact Email Address',
      visible: true,
      activeStatus: true,
      sort: false,
      filter: true,
      minWidth: 150,
      isDate: false
    },
    {
      field: 'availableMarketsList',
      header: 'Type',
      visible: true,
      activeStatus: true,
      sort: false,
      filter: true,
      minWidth: 100,
      isDate: false
    },
    {
      field: 'lioAction',
      header: 'Details',
      visible: true,
      activeStatus: true,
      sort: false,
      filter: false,
      minWidth: 200,
      isDate: false
    }
  ]

  additionalColumns = [
    {
      field: 'phoneNumber',
      header: 'Contact Phone Number',
      visible: false,
      activeStatus: false,
      sort: false,
      filter: true,
      minWidth: 300,
      isDate: false
    },
    {
      field: 'address',
      header: 'Address',
      visible: false,
      activeStatus: false,
      sort: false,
      filter: true,
      minWidth: 300,
      isDate: false
    },
    {
      field: 'companyName',
      header: 'Company Name',
      visible: false,
      activeStatus: false,
      sort: false,
      filter: true,
      minWidth: 300,
      isDate: false
    },
    {
      field: 'companyFunction',
      header: 'Company Function',
      visible: false,
      activeStatus: false,
      sort: false,
      filter: true,
      minWidth: 300,
      isDate: false
    },
    {
      field: 'vatCode',
      header: 'VAT Code',
      visible: false,
      activeStatus: false,
      sort: false,
      filter: true,
      minWidth: 150,
      isDate: false
    },
    {
      field: 'AcerCode',
      header: 'ACER Code',
      visible: false,
      activeStatus: false,
      sort: false,
      filter: true,
      minWidth: 150,
      isDate: false
    }
  ]

  allColumns = [...this.defaultColumns, ...this.additionalColumns]
}
