import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { FunctionInterface } from '@app/shared/types/function.interface';
import { FunctionStateInterface } from '@app/shared/types/function.state.interface';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import * as FunctionActions from '../../../../store/actions/function.actions'
import { UpdateFunctionCommand } from '@app/shared/types/commands/updateFunctionCommand.model';

@Component({
  selector: 'app-update-function',
  templateUrl: './update-function.component.html',
  styleUrls: ['./update-function.component.scss']
})
export class UpdateFunctionComponent {
  @Input() function: FunctionInterface
  @Input() tableFilterOrSortOptions: TableFilteringSortingOptions
  visible = false;
  functionForm: FormGroup;
  destroyed$ = new Subject<boolean>()

  constructor(
    private formBuilder: FormBuilder,
    private functionStore: Store<FunctionStateInterface>,
    private updates$: Actions,
    private messageService: MessageService
  ){
    this.functionForm = this.formBuilder.group({
      functionCode: new FormControl({ value: null, disabled: true }, Validators.required),
      functionName: new FormControl({ value: null, disabled: true }, Validators.compose([
        Validators.pattern('^[/-]*[A-Z][a-z]*(([ ]+|[/-]+)[A-Z][a-z]*)*[/-]?$'),
        Validators.maxLength(70),
        Validators.required
      ])),
      definition: new FormControl(null, Validators.compose([      
        Validators.maxLength(3000)
      ])),
      functionStatus: new FormControl(null, Validators.required),
      functionValidMarket: new FormControl(null, Validators.required),
      hasBeenSubmitted: new FormControl(false, null)
    })
  }

  showDialog({func, tableFilterOrSortOptions}: any){
    this.function = func
    this.tableFilterOrSortOptions = tableFilterOrSortOptions;

    this.visible = true;

    this.functionForm.controls['functionCode'].setValue(this.function.code)
    this.functionForm.controls['functionName'].setValue(this.function.name)
    this.functionForm.controls['definition'].setValue(this.function.definition)
    this.functionForm.controls['functionStatus'].setValue(this.function.status)
    this.functionForm.controls['functionValidMarket'].setValue(this.function.validMarket)
  }

  onSubmit(){
    if(this.functionForm.valid){
      var updateFunction : UpdateFunctionCommand = {
        id: this.function.id,
        code: this.functionForm.controls['functionCode'].value,
        name: this.functionForm.controls['functionName'].value,
        definition: this.functionForm.controls['definition'].value,
        status: this.functionForm.controls['functionStatus'].value,
        validMarket: this.functionForm.controls['functionValidMarket'].value
      }

      this.functionStore.dispatch(FunctionActions.updateFunction({func: updateFunction}))

      this.updates$
        .pipe(ofType(FunctionActions.UPDATE_FUNCTION_SUCCESFUL), takeUntil(this.destroyed$))
        .subscribe(() => {
          this.functionStore.dispatch(
            FunctionActions.getFunctions({
              tableOptions: this.tableFilterOrSortOptions
            })
          )
          this.visible = false
          this.functionForm.reset()
        }
      )
    }
    else{
      this.functionForm.controls['hasBeenSubmitted'].setValue(true)
      this.functionForm.updateValueAndValidity()
      this.messageService.add({
        key: 'toast',
        severity: 'error',
        summary: 'Please fill all required fields in',
        detail: 'Check the fields for details'
      })
    }
  }

  onClose() {
    this.functionForm.reset()
    this.visible = false
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
