<p-dialog
  header="Create new message"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  closeIcon="icon icon--close"
  (onHide)="onClose()"
>
<ng-template pTemplate="header">
    <h2 class="text-secondary">Update message</h2>
  </ng-template>

  <form [formGroup]="messageForm">
    <app-message [messageForm]="messageForm" [isEdit]="true"></app-message>
  </form>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-end gap-3">
      <p-button
        label="Cancel"
        styleClass="p-button-outlined p-button-secondary"
        [outlined]="true" severity="secondary"
        (click)="onClose()"
      ></p-button>
      <p-button
        label="Save"
        type="submit"
        (click)="onSubmit()"
      ></p-button>
    </div>
  </ng-template>

</p-dialog>

