import { Component, OnDestroy, OnInit } from '@angular/core'
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum'
import { ReportInterface } from '@app/shared/types/report.interface'
import { Store } from '@ngrx/store'
import * as ReportActions from '../../../store/actions/report.actions'
import {
  requestActionFilterOptions,
  requestActionsList
} from '@app/shared/types/enums/request-action.enum'
import { RequestInterface } from '@app/shared/types/request.interface'
import { Observable, Subscription } from 'rxjs'
import { getAllApprovedRequests, totalRecordsSelector } from '@app/store/selectors/report.selector'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { ExportPlace } from '@app/shared/types/enums/export-place.enum'
import { eicCodeTypesList } from '@app/shared/types/enums/eic-code-type.enum'
import { ExportTypeCodes } from '@app/shared/types/enums/export-type-codes.enum'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-all-approved-requests',
  templateUrl: './all-approved-requests.component.html',
  styleUrls: ['./all-approved-requests.component.scss']
})
export class AllApprovedRequestsComponent implements OnInit {
  today: number = Date.now()
  settingType: number = SettingsTypes.allApprovedRequests
  approvedRequests: RequestInterface[] = []
  report$: Observable<RequestInterface[]>
  reportsSub: Subscription

  requestActionsList = requestActionsList
  requestActionFilterOptions = requestActionFilterOptions
  eicCodeTypesOptions = eicCodeTypesList


  pageNumber: number | undefined = 0
  rowsPerPage: number | undefined = 10

  totalRecords$: Observable<number>
  totalRecords: number
  firstRow: number = 0
  recordSub: Subscription
  querySub: Subscription

  exportOptionColumns: TableFilteringSortingOptions = new TableFilteringSortingOptions()
  exportPlace: ExportPlace = ExportPlace.AllApprovedRequests

  exportOptions: any[] = [ExportTypeCodes.XML, ExportTypeCodes.ZIP]

  constructor(private reportStore: Store<ReportInterface>,
    private route: ActivatedRoute,
    private router: Router) {
    this.report$ = this.reportStore.select(getAllApprovedRequests)
    this.totalRecords$ = this.reportStore.select(totalRecordsSelector)
  }

  ngOnInit(): void {
    
    let paramsOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions();

    this.querySub = this.route.queryParams
      .subscribe(params => {
        Object.keys(params).forEach(key => {
          if(params[key] != null){
            paramsOptions[key] = params[key]
          }
        })
      })

    this.pageNumber = paramsOptions.pageNumber
    this.rowsPerPage = paramsOptions.rowsPerPage

    if (!this.pageNumber || !this.rowsPerPage) {
      this.pageNumber = 0
      this.rowsPerPage = 10
    }

    this.firstRow = this.rowsPerPage * this.pageNumber
    this.rowsPerPage = parseInt(this.rowsPerPage?.toString(), 10)

    this.recordSub = this.totalRecords$.subscribe((total) => {
      this.totalRecords = total
    })

    this.exportOptionColumns.pageNumber = this.pageNumber
    this.exportOptionColumns.rowsPerPage = this.rowsPerPage
    this.getData(this.exportOptionColumns)
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {
    let props = tableFilterOrSortOptions
    Object.keys(props).forEach(function (key) {
      if (props[key] == null) {
        delete props[key]
      }
    })
    this.exportOptionColumns = props

    this.getData(props)
  }

  getData(tableOptions: TableFilteringSortingOptions) {
    tableOptions.getFunctions = false;
    this.reportStore.dispatch(ReportActions.getAllApprovedRequestsReport({ props: tableOptions }))

    this.reportsSub = this.report$.subscribe((data) => {
      this.approvedRequests = data.map((item) => {
        let req = Object.assign({}, item, {
          requestActionObj: {
            label: requestActionsList[item.requestAction].label,
            value: item.requestAction
          },
          eicCodeTypeObj: {
            label: eicCodeTypesList.filter((obj) => {
              return obj.value == item.eicCodeType
            })[0].label,
            value: item.eicCodeType
          }
        })
        return req
      })
    })
    this.setQueryParams()
  }

  setQueryParams() {
    let queryParams : TableFilteringSortingOptions = new TableFilteringSortingOptions()

    Object.keys(this.exportOptionColumns).forEach(key => {

      if(this.exportOptionColumns[key] != null){
        if(key != "sortBy" && key != "getFunctions"){
          queryParams[key] = this.exportOptionColumns[key]
        }
      }
    });

    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })
  }

  ngOnDestroy() {
    this.recordSub.unsubscribe()
    this.reportsSub.unsubscribe()
  }
}
