import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input() messageForm: FormGroup;
  @Input() isEdit: boolean = false;

  minDate: Date;
  
  ngOnInit(){
    this.minDate = new Date()
  }

  onClose(date, isStartDate) {
    if(date != null) {
      var d = new Date(date)
      d.setSeconds(0)
      if(isStartDate){
        this.messageForm.controls['dateGroup'].get('startDate')?.setValue(d);
      }
      else{
        this.messageForm.controls['dateGroup'].get('endDate')?.setValue(d);
      }
      
    }
    return null;
  }
}
