import { SelectItem } from "primeng/api"
import { FunctionValidMarketObj } from "../function.interface"

export enum FunctionValidMarketEnum{
    Electricity = 0,
    Gas = 1,
    ElectricityAndGas = 2
}

export const functionValidMarketList: SelectItem[] = [
    {label: "Electricity", value: 0, icon: "Electricity"},
    {label: "Gas", value: 1, icon: "Gas"},
    {label: "Electricity and Gas", value: 2, icon: "ElectricityAndGas"}
]

export const functionValidMarketFilterOptions : FunctionValidMarketObj[] = [
    {label: "Electricity", value: 0},
    {label: "Gas", value: 1},
    {label: "Electricity and Gas", value: 2}
  ]