export class MessagesaTableHeaders {
    allColumns = [
        { field: 'startDate', header: 'Start Date', visible: true, activeStatus: true, sort: false, filter: false, minWidth: 130, isDate: true},
        { field: 'endDate', header: 'End Date', visible: true, activeStatus: true, sort: false, filter: false, minWidth: 130, isDate: true},
        { field: 'messageType', header: 'Message Type', visible: true, activeStatus: true, sort: false, filter: true , minWidth: 150, isDate: false},
        { field: 'createdBy', header: 'Created By', visible: true, activeStatus: true, sort: false, filter: false, minWidth: 150, isDate: false},
        { field: 'creationDate', header: 'Created Date', dateFormat: "dd-MM-yyyy HH:mm", visible: true, activeStatus: true, sort: true, filter: true, minWidth: 130, isDate: true },
        { field: 'updatedBy', header: 'Updated By', visible: true, activeStatus: true, sort: false, filter: false, minWidth: 150, isDate: false },
        { field: 'updateDate', header: 'Update Date', dateFormat: "dd-MM-yyyy HH:mm", visible: true, activeStatus: true, sort: true, filter: true, minWidth: 130, isDate: true },
        { field: 'messageDetail', header: 'Details', visible: true, activeStatus: true, sort: false, filter: false, minWidth: 120, isDate: false }
    ];
}