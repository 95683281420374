export class LioMenuItems {
    removeImpersonate: () => void;
    constructor(public lioNumber = "", removeImpersonate: { (): void; (): void; }) {
        this.lioNumber = lioNumber;
        this.removeImpersonate = removeImpersonate;
    };

    lioItems = [
        {
            label: 'My EIC codes requests',
            icon: 'icon icon--terminal',
            routerLink: '/lio/' + this.lioNumber + '/requests/',
        },
        {
            label: 'My LIO',
            routerLink: '/lio/' + this.lioNumber,
            icon: 'icon icon--user',
            routerLinkActiveOptions: { exact: true }
        },
        {
            label: 'My EIC codes',
            icon: 'icon icon--eye',
            routerLink: '/lio/' + this.lioNumber + '/eics/',
        },        
        {
            label: 'Stop impersonation',
            command: () => this.removeImpersonate(),
            routerLinkActiveOptions: { exact: true }
        }
    ];

    menu = this.lioItems.slice(0,3)
    
    impersonationMenu = this.lioItems
}