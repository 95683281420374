import { createReducer, on } from '@ngrx/store'
import * as messageActions from '../actions/message.action'
import { MessageStateInterface } from '@app/shared/types/message-state.interface'

export const initialState: MessageStateInterface = {
  isLoading: false,
  messages: [],
  error: null,
  pageNumber: 0,
  rowsPerPage: 10,
  totalRecords: 0
}

export const messageReducer = createReducer(
  { ...initialState },
  on(messageActions.clearMessages, (state) => ({
    ...state,
    messages: []
  })),
  on(messageActions.getMessages, (state) => ({
    ...state,
    isLoading: true
  })),
  on(messageActions.getMessagesSuccesfully, (state, action) => ({
    ...state,
    isLoading: false,
    messages: action.messages
  })),
  on(messageActions.getMessagesFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
    messages: []
  })),
  on(messageActions.getAllMessages, (state) => ({
    ...state,
    isLoading: true
  })),
  on(messageActions.getAllMessagesSuccesfully, (state, action) => ({
    ...state,
    isLoading: false,
    messages: action.paginatedResult.entities,
    totalRecords: action.paginatedResult.totalRecords,
		pageNumber: action.paginatedResult.pageNumber,
		rowsPerPage: action.paginatedResult.rowsPerPage
  })),
  on(messageActions.getAllMessagesFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
    messages: []
  })),
  on(messageActions.createMessage, (state) => ({
		...state,
		isLoading: true
	})),
	on(messageActions.createMessageSuccesfully, (state) => ({
		...state,
		isLoading: false
	})),
	on(messageActions.createMessageFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	}))
)
