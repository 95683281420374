import { CreateMessageCommand } from "@app/shared/types/commands/createMesageCommand.model"
import { UpdateMessageCommand } from "@app/shared/types/commands/updateMessageCommand.model"
import { TableFilteringSortingOptions } from "@app/shared/types/export-options-columns.interface"
import { MessageInterface } from "@app/shared/types/message.interface"
import { PaginatedResult } from "@app/shared/types/paginated-result.interface"
import { createAction, props } from "@ngrx/store"

export const GET_MESSAGES = '[MESSAGE Component] Get messages'
export const GET_MESSAGES_SUCCESSFUL = '[MESSAGE Component] Get messages success'
export const GET_MESSAGES_FAILED = '[MESSAGE Component] Get messages failed'

export const GET_ALL_MESSAGES = '[MESSAGE Component] Get all messages'
export const GET_ALL_MESSAGES_SUCCESSFUL = '[MESSAGE Component] Get all messages success'
export const GET_ALL_MESSAGES_FAILED = '[MESSAGE Component] Get all messages failed'

export const CREATE_MESSAGE = '[MESSAGE Component] Create message'
export const CREATE_MESSAGE_SUCCESSFUL = '[MESSAGE Component] Create message success'
export const CREATE_MESSAGE_FAILED = '[MESSAGE Component] Create message failed'

export const UPDATE_MESSAGE = '[MESSAGE Component] Update message'
export const UPDATE_MESSAGE_SUCCESSFUL = '[MESSAGE Component] Update message success'
export const UPDATE_MESSAGE_FAILED = '[MESSAGE Component] Update message failed'

export const CLEAR_MESSAGES = '[MESSAGE Component] Clear Messages'


export const clearMessages = createAction(CLEAR_MESSAGES);

export const getMessages = createAction(GET_MESSAGES)

export const getMessagesSuccesfully = createAction(
    GET_MESSAGES_SUCCESSFUL,
    props<{messages: MessageInterface[]}>()
)

export const getMessagesFailed = createAction(
    GET_MESSAGES_FAILED,
    props<{error: string}>()
)

export const getAllMessages = createAction(
    GET_ALL_MESSAGES,
    props<{ tableOptions: TableFilteringSortingOptions | undefined }>()
)

export const getAllMessagesSuccesfully = createAction(
    GET_ALL_MESSAGES_SUCCESSFUL,
    props<{ paginatedResult: PaginatedResult<MessageInterface>}>()
)

export const getAllMessagesFailed = createAction(
    GET_ALL_MESSAGES_FAILED,
    props<{error: string}>()
)

export const createMessage = createAction(
    CREATE_MESSAGE,
    props<{ message : CreateMessageCommand}>()
)

export const createMessageSuccesfully = createAction(
    CREATE_MESSAGE_SUCCESSFUL
)

export const createMessageFailed = createAction(
    CREATE_MESSAGE_FAILED,
    props<{error : string}>()
)

export const updateMessage = createAction(
    UPDATE_MESSAGE,
    props<{ message : UpdateMessageCommand}>()
)

export const updateMessageSuccesfully = createAction(
    UPDATE_MESSAGE_SUCCESSFUL
)

export const updateMessageFailed = createAction(
    UPDATE_MESSAGE_FAILED,
    props<{error : string}>()
)