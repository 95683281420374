import { immerOn } from 'ngrx-immer/store'
import { createReducer, on } from '@ngrx/store'
import * as RequestActions from '../actions/request.actions'
import { RequestStateInterface } from '@app-shared-types/request-state.interface'

export const initialState: RequestStateInterface = {
  isLoading: false,
  requests: [],
  latestRequests: [],
  pageNumber: 0,
  rowsPerPage: 10,
  totalRecords: 0,
  error: null,
  bulkRequestStatus: [],
  standardAnswers: []
}

export const requestReducer = createReducer(
  { ...initialState },
  on(RequestActions.getRequestsOfLio, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.getRequestsOfLioSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    requests: action.paginatedResult.entities,
    totalRecords: action.paginatedResult.totalRecords,
    pageNumber: action.paginatedResult.pageNumber,
    rowsPerPage: action.paginatedResult.rowsPerPage
  })),
  on(RequestActions.getRequestsOfLioFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(RequestActions.getRequestById, (state) => ({
    ...state,
    isLoading: true
  })),
  immerOn(RequestActions.getRequestByIdSuccessfully, (state, action) => {
    state.isLoading = false
    state.requests = state.requests.filter((item) => item.id != action.request.id)
    state.requests.push(action.request)
  }),
  on(RequestActions.getRequestByIdFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(RequestActions.getAllRequests, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.getAllRequestsSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    requests: action.paginatedResult.entities,
    totalRecords: action.paginatedResult.totalRecords,
    pageNumber: action.paginatedResult.pageNumber,
    rowsPerPage: action.paginatedResult.rowsPerPage
  })),
  on(RequestActions.getAllRequestsFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(RequestActions.approvePendingRequest, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.approvePendingRequestSuccessfuly, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.approvePendingRequestFailed, (state, action) => ({
    ...state,
    isLoading: false
  })),
  on(RequestActions.bulkApprovePendingRequest, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.bulkApprovePendingRequestSuccessfuly, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.bulkApprovePendingRequestFailed, (state, action) => ({
    ...state,
    isLoading: false
  })),
  on(RequestActions.getLatestFivePendingRequests, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.getLatestFivePendingRequestsSuccesfuly, (state, action) => ({
    ...state,
    isLoading: false,
    requests: action.requests
  })),
  on(RequestActions.getLatestFivePendingRequestsFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(RequestActions.getLatestFiveRequestsByLio, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.getLatestFiveRequestsByLioSuccesfuly, (state, action) => ({
    ...state,
    isLoading: false,
    latestRequests: action.requests
  })),
  on(RequestActions.getLatestFiveRequestsByLioFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(RequestActions.createRequest, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.createRequestSuccessfully, (state, action) => ({
    ...state,
    isLoading: false
  })),
  on(RequestActions.createRequestFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(RequestActions.createRequests, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.createRequestsSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    bulkRequestStatus: [...action.bulkRequestsStatus]
  })),
  on(RequestActions.createRequestsFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(RequestActions.getStandardApprovalAnswers, (state) => ({
    ...state,
    isLoading: true
  })),
  on(RequestActions.getStandardApprovalAnswersSuccesfully, (state, action) => ({
    ...state,
    isLoading: false,
    standardAnswers: action.answers
  })),
  on(RequestActions.getStandardApprovalAnswersFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
)
